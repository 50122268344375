import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import SignInView from "../views/SignInView.vue";
// import HomeView from "../views/HomeView.vue";
import CompanyView from "../views/CompanyView.vue";
import CompaniesView from "../views/CompaniesView.vue";
import CompanyCrawlRequestsView from "../views/CompanyCrawlRequestsView.vue";
import EmailsView from "../views/EmailsView.vue";
import ForestCrawlRequestsView from "../views/ForestCrawlRequestsView.vue";
import ForestView from "../views/ForestView.vue";
import ForestsView from "../views/ForestsView.vue";
import InvoiceCreditNoteView from "../views/InvoiceCreditNoteView.vue";
import InvoiceView from "../views/InvoiceView.vue";
import LogoutView from "../views/LogoutView.vue";
import UserView from "../views/UserView.vue";
import UsersView from "../views/UsersView.vue";
import VisitorsView from "../views/VisitorsView.vue";
import TreeReportsView from "../views/TreeReportsView.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: { name: "Users" },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignInView,
    meta: {
      title: "SignIn",
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutView,
    meta: {
      needAuth: true,
      title: "Logout",
    },
  },
  {
    path: "/users",
    name: "Users",
    component: UsersView,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Users",
    },
  },
  {
    path: "/users/:id",
    name: "User",
    component: UserView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "User",
    },
  },
  {
    path: "/forests",
    name: "Forests",
    component: ForestsView,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Forests",
    },
  },
  {
    path: "/forests/:id",
    name: "Forest",
    component: ForestView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Forest",
    },
  },
  {
    path: "/forests/:id/crawl-requests",
    name: "ForestCrawlRequests",
    component: ForestCrawlRequestsView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Forest Crawl Requests",
    },
  },
  {
    path: "/companies/:id/crawl-requests",
    name: "CompanyCrawlRequests",
    component: CompanyCrawlRequestsView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Company Crawl Requests",
    },
  },
  {
    path: "/companies/:id",
    name: "Company",
    component: CompanyView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Company",
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: CompaniesView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Companies",
    },
  },
  {
    path: "/invoices/:id/credit-note",
    name: "InvoiceCreditNote",
    component: InvoiceCreditNoteView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Invoice Refund",
    },
  },
  {
    path: "/invoices/:id",
    name: "InvoiceView",
    component: InvoiceView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Invoice",
    },
  },
  {
    path: "/visitors",
    name: "Visitors",
    component: VisitorsView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Visitors",
    },
  },
  {
    path: "/emails",
    name: "Emails",
    component: EmailsView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Emails",
    },
  },
  {
    path: "/tree-planting",
    name: "TreePlanting",
    component: TreeReportsView,
    props: true,
    meta: {
      layout: "sidebar-layout",
      needAuth: true,
      title: "Tree Reports",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    return next({ name: "Home" });
  }
  const needAuth = to.matched.some((record) => record.meta.needAuth);
  let isAuth = store.state.isAuthenticated;
  /* access to activate the account for auth and non auth user */
  /* after user registered, he gets token, so the user can be auth or no auth */
  if (isAuth && to.name === "Logout") {
    return next();
  }

  if (needAuth && !isAuth) {
    if (to.path === "/") {
      return next({
        name: "SignIn",
        query: to.query,
      });
    }
    return next({
      name: "SignIn",
      query: Object.assign({}, to.query, { redirect: to.path }),
    });
  }

  if (isAuth && !needAuth) {
    return next({ name: "Home" });
  }

  if (to.meta.title) {
    document.title = "ReviewForest Admin - " + to.meta.title;
  }

  return next();
});

export default router;

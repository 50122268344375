<template>
  <v-card>
    <template>
      <v-card-title>
        Forest Trees
        {{ $options.PLANTING_PROJECTS[projectId] }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    </template>
    <template>
      <v-data-table
        :headers="headers"
        :items="listOfTrees"
        :server-items-length="totalAmount"
        item-key="id"
        class="elevation-1"
        v-model="refundTrees"
        show-select
        :loading="loading"
        loading-text="Loading... Please wait"
        :options.sync="options"
      >
        <template v-slot:[`item.projectId`]="{ item }">
          {{ $options.PLANTING_PROJECTS[item.projectId] }}
        </template>
        <template v-slot:[`item.stripeInvoiceNumber`]="{ item }">
          <router-link
            :to="{
              name: 'InvoiceView',
              params: { id: item.stripeInvoiceId },
            }"
          >
            {{ item.stripeInvoiceNumber }}
          </router-link>
        </template>
        <template v-slot:top>
          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Are you sure you want to delete this tree?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="closeDelete"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="deleteTreeConfirm"
                >
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="ma-2"
                text
                icon
                color="primary"
                @click="deleteTree(item)"
                :disabled="!!item.stripeInvoiceId"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>Remove tree</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.text`]="{ item }">
          <v-tooltip top open-delay="1000">
            <template v-slot:activator="{ on }">
              <span v-on="on" v-if="item.text">
                {{ item.text.substring(0, 100) + "..." }}
              </span>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>
    <template>
      <template>
        <v-progress-linear
          :active="actionsLoading"
          :indeterminate="actionsLoading"
          absolute
          bottom
          color="indigo darken-2"
        ></v-progress-linear>
      </template>
      <v-card-text v-if="refundTrees.length > 0">
        {{ refundTrees.length }} selected tree(s):
        {{ refundTrees.map((tree) => tree.name).join(", ") }}
      </v-card-text>
      <v-card-text v-else>All trees</v-card-text>
      <v-alert v-if="creditNoteError" dense outlined type="error">
        {{ creditNoteError }}
      </v-alert>
    </template>
    <template>
      <v-btn
        color="primary"
        @click="previewCreditNote()"
        :disabled="selectedStripeInvoiceIds.length !== 1 && !invoice"
      >
        Preview Credit Note
      </v-btn>
      <v-divider class="mx-4" inset vertical></v-divider>
    </template>
    <template v-if="previewedCreditNote">
      <v-card-text>
        Total credit note: {{ previewedCreditNote.total / 100 }}
        {{ previewedCreditNote.currency }}
      </v-card-text>
      <v-card-text v-if="isIssueCreditNoteAllowed">
        <template v-if="creditNoteReasons">
          <v-row>
            <v-col cols="3">
              <v-select
                :items="creditNoteReasons"
                density="compact"
                value="other"
                v-model="reason"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
        </template>
        <template v-if="creditNoteMemosDE && creditNoteMemosEN">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Memo templates"
                :items="
                  invoice.language === 'de'
                    ? creditNoteMemosDE
                    : creditNoteMemosEN
                "
                density="compact"
                value="other"
                v-model="memo"
                item-text="item"
                item-value="item"
              ></v-select>
            </v-col>
          </v-row>
        </template>
        <v-responsive>
          <v-text-field
            label="What's the reason for the removal"
            v-model="memo"
          ></v-text-field>
        </v-responsive>
      </v-card-text>
      <v-dialog v-model="dialog" persistent max-width="550">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!isIssueCreditNoteAllowed"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Issue Credit Note
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">Issue Credit Note</v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td style="width: 50px">Number</td>
                    <td>{{ previewedCreditNote.number }}</td>
                  </tr>
                  <tr>
                    <td>Memo</td>
                    <td>{{ memo }}</td>
                  </tr>
                  <tr>
                    <td>Reason</td>
                    <td>{{ reason }}</td>
                  </tr>
                  <tr>
                    <td>How to credit</td>
                    <td>{{ credit }}</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>
                      {{ previewedCreditNote.total / 100 }}
                      {{ previewedCreditNote.currency }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="
                dialog = false;
                issueCreditNote();
              "
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
import { API } from "../../api";

import {
  DEFAULT_ITEMS_PER_PAGE,
  INVOICE_STATUS,
  INVOICE_TYPES,
  PLANTING_PROJECTS,
} from "../../constants";

export default {
  name: "CreditNote",
  DEFAULT_ITEMS_PER_PAGE,
  INVOICE_STATUS,
  INVOICE_TYPES,
  PLANTING_PROJECTS,
  props: {
    forestId: {
      type: String,
      required: true,
    },
    trees: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectable: false,
      memo: null,
      reason: null,
      dialog: false,
      dialogDelete: false,
      credit: "refund",
      listOfTrees: this.trees.data,
      refundTrees: [],
      selectedStripeInvoiceIds: [],
      isIssueCreditNoteAllowed: false,
      toSubmitInvoiceLineItems: null,
      previewedCreditNote: null,
      creditNoteError: null,
      loading: false,
      actionsLoading: false,
      projectId: null,
      search: "",
      invoice: null,
      creditNoteTypes: [
        {
          name: "Credit Balance",
          value: "credit_balance",
        },
        {
          name: "Refund",
          value: "refund",
        },
      ],
      creditNoteReasons: [
        {
          name: "Credit note reason",
          value: null,
        },
        {
          name: "Duplicate charge",
          value: "duplicate",
        },
        {
          name: "Fraudulent charge",
          value: "fraudulent",
        },
        {
          name: "Order change",
          value: "order_change",
        },
        {
          name: "Product unsatisfactory",
          value: "product_unsatisfactory",
        },
      ],
      creditNoteMemosDE: [
        "[NUMBER] Baum wurde erstattet, weil der Kunde den Baum löschen wollte (gefälschte Bewertung).",
        "[NUMBER] gelöschte Bäume.",
        "[NUMBER] Baum wurde erstattet, weil der Kunde den Baum löschen wollte (falscher Name) und stattdessen einen anderen gepflanzt hat.",
        "Löschung [NUMBER] Baumes, da Bewertung auf [PLATFORM] gelöscht wurde.",
        "Druckbestellung mit dem falschen Standort, Kunde will mit anderem Standort Karten bestellen.",
        "Gutschrift und Rückerstattung auf Basis umgehender Kündigung am finalen Testtag.",
        "Kunde hat falsche Anzahl von Karten bestellt, bestellt gleich [NUMBER] Stück. Credit Balance wird angewandt.",
      ],
      creditNoteMemosEN: [
        "[NUMBER] tree refunded because customer wanted to delete tree (fake review).",
        "[NUMBER] deleted trees.",
        "[NUMBER] tree refunded because customer wanted to delete tree (wrong name) & planted another one instead.",
        "Customer's sub ([DATE] to [DATE]) was deactivated because of uncollectable invoice. We reactivated it -> new sub [DATE] to [DATE] -> refund of [DATE_RANGE].",
        "Refund because account was deactivated for [DATE_RANGE]",
        "Customer cancelled another [NUMBER] forests -> just [NUMBER] forest left.",
        "Customer has [NUMBER] forests and not [NUMBER] -> refund of [NUMBER] forest.",
        "Print order cancelled.",
        "Customer wishes to cancel.",
        "Unused [PLAN_NAME] Plan",
        "Refund for [NUMBER] months because customer cancelled.",
        "Refund because customer wants to stop participating.",
      ],
      headers: [
        { text: "Name", value: "name" },
        { text: "Date", value: "date" },
        { text: "Score", value: "score" },
        { text: "Project", value: "projectId" },
        { text: "Occasion", value: "plantingOccasion" },
        { text: "Platform Type", value: "platformType" },
        { text: "Tree Type", value: "type" },
        { text: "Text", value: "text" },
        { text: "Invoice Number", value: "stripeInvoiceNumber" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        date: "",
        score: "",
        plantingProject: "",
        occasion: "",
        platformType: "",
        type: "",
        text: "",
        invoiceNumber: "",
      },
      defaultItem: {
        name: "",
        date: "",
        score: "",
        plantingProject: "",
        occasion: "",
        platformType: "",
        type: "",
        text: "",
        invoiceNumber: "",
      },
      options: {
        page: 1,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      totalAmount: this.trees.count,
    };
  },
  watch: {
    refundTrees: {
      async handler(val) {
        this.creditNoteError = false;
        if (!val) {
          this.refundTrees = [];
        }
        this.selectedStripeInvoiceIds =
          val?.length > 0
            ? [...new Set(val.map((x) => x.stripeInvoiceId).filter((x) => !!x))]
            : [];
        if (this.selectedStripeInvoiceIds.length > 1) {
          this.creditNoteError =
            "Please select only trees from the same invoice";
        }
        this.isIssueCreditNoteAllowed = false;
      },
      immediate: true,
      deep: true,
    },
    selectedStripeInvoiceIds: {
      async handler(val) {
        const selectedInvoiceId = val[0];
        if (
          selectedInvoiceId &&
          selectedInvoiceId !== this.invoice?.stripeInvoiceId
        ) {
          await this.getInvoice(selectedInvoiceId);
        }
      },
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    projectId: {
      handler: "debounceFetching",
    },
    search: {
      handler: "debounceFetching",
    },
    options: {
      handler: "debounceFetching",
      deep: true,
    },
  },
  computed: {
    searchQuery() {
      const trimmedSearch = this.search.trim();
      return trimmedSearch === "" ? null : trimmedSearch;
    },
    emptyTrees() {
      return this.refundTrees.filter((t) => !t.invoiceNumber);
    },
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.isIssueCreditNoteAllowed = false;
      this.timerId = setTimeout(() => {
        this.getForestTrees();
      }, 500);
    },
    async getInvoice(stripeInvoiceId) {
      this.actionsLoading = true;
      await API.getInvoice(stripeInvoiceId)
        .then((response) => {
          this.invoice = response;
        })
        .catch((error) => {
          this.creditNoteError = `Error: ${error.response?.data?.message}`;
        })
        .finally(() => {
          this.actionsLoading = false;
        });
    },
    async getForestTrees() {
      this.loading = true;

      await API.getForestTrees({
        forestId: this.forestId,
        params: {
          sortBy: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? "desc" : "asc",
          page: this.options.page,
          pageSize:
            this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage,
          query: this.searchQuery,
        },
      })
        .then((response) => {
          this.listOfTrees = response.data;
          this.totalAmount = response.count;
        })
        .catch((error) => {
          this.creditNoteError = `Error: ${error.response?.data?.message}`;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async previewCreditNote() {
      this.creditNoteError = false;
      this.actionsLoading = true;
      const previewTreeIds = this.refundTrees;

      this.toSubmitInvoiceLineItems = this.invoice?.lines
        ?.map((el) => {
          const item = { id: el.id };
          if (previewTreeIds?.length > 0) {
            if (
              previewTreeIds.filter((tr) => tr.projectId === el.projectId)
                .length > 0
            ) {
              item.treeIds = previewTreeIds
                .filter((tr) => tr.projectId === el.projectId)
                .map((tx) => tx.id);
            } else {
              delete item.id;
              return;
            }
          } else {
            item.projectId = el.projectId;
          }
          return item;
        })
        .filter((item) => !!item);

      await API.previewCreditNote({
        stripeInvoiceId: this.invoice.stripeInvoiceId,
        data: { invoiceLineItems: this.toSubmitInvoiceLineItems },
      })
        .then((response) => {
          this.previewedCreditNote = response;
          this.isIssueCreditNoteAllowed = true;
        })
        .catch((error) => {
          this.creditNoteError = `Error: ${error.response?.data?.message}`;
        })
        .finally(() => {
          this.actionsLoading = false;
        });
    },
    async issueCreditNote() {
      this.actionsLoading = true;
      await API.issueCreditNote({
        stripeInvoiceId: this.invoice.stripeInvoiceId,
        data: {
          invoiceLineItems: this.toSubmitInvoiceLineItems,
          memo: this.memo,
          reason: this.reason,
          credit: this.credit,
        },
      })
        .then(() => {
          this.$router.push({
            name: "Company",
            params: { id: this.invoice.ownedBy },
          });
        })
        .catch((error) => {
          this.creditNoteError = `Error: ${error.response?.data?.message}`;
        })
        .finally(() => {
          this.actionsLoading = false;
        });
    },
    deleteTree(item) {
      this.editedIndex = this.listOfTrees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteTreeConfirm() {
      this.listOfTrees.splice(this.editedIndex, 1);
      this.loading = true;
      await API.removeTree({
        forestId: this.forestId,
        treeId: this.editedItem.id,
      })
        .then(() => {
          this.totalAmount -= 1;
        })
        .catch((error) => {
          this.creditNoteError = `Error: ${error.response?.data?.message}`;
        })
        .finally(() => {
          this.loading = false;
          this.closeDelete();
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

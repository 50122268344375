<template>
  <div v-if="!loading">
    <v-breadcrumbs divider=">" :items="breadcrumbs" exact></v-breadcrumbs>
    <invoice-details :invoice="invoice"></invoice-details>
  </div>
</template>

<script>
import { API } from "../api";
import InvoiceDetails from "../components/Invoice/InvoiceDetails.vue";

export default {
  name: "InvoiceView",
  components: {
    InvoiceDetails,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      invoice: {},
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Companies",
          to: { name: "Companies" },
        },
        {
          text: this.invoice.companyName,
          to: { name: "Company", params: { id: this.invoice.ownedBy } },
        },
        {
          text: this.id,
          to: { name: "InvoiceView", params: { id: this.id } },
        },
      ];
    },
  },
  async created() {
    this.isLoading = true;
    try {
      this.invoice = await API.getInvoice(this.id);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

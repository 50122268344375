<template>
  <v-card>
    <v-card-title>
      Companies
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="companies"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalAmount"
      :footer-props="{
        'items-per-page-options': $options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <router-link :to="{ name: 'Company', params: { id: item.id } }">{{
          item.name
        }}</router-link>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { API } from "../../api";
import { formatDate } from "../../helpers";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "../../constants";

export default {
  name: "CompaniesTable",
  DEFAULT_ITEMS_PER_PAGE_OPTIONS,
  data() {
    return {
      formatDate,
      timerId: null,
      loading: true,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Created at",
          sortable: true,
          value: "createdAt",
        },
      ],
      companies: [],
      totalAmount: 0,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
    };
  },
  computed: {
    searchQuery() {
      const trimmedSearch = this.search.trim();
      return trimmedSearch === "" ? null : trimmedSearch;
    },
  },
  watch: {
    search: {
      handler: "debounceFetching",
    },
    options: {
      handler: "debounceFetching",
      deep: true,
    },
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.getCompanies();
      }, 500);
    },
    async getCompanies() {
      this.loading = true;
      try {
        const companies = await API.getCompanies({
          sortBy: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? "desc" : "asc",
          page: this.options.page,
          pageSize:
            this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage,
          query: this.searchQuery,
        });
        this.companies = companies.data;
        this.totalAmount = companies.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

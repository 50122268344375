<template>
  <users-table></users-table>
</template>
<script>
import UsersTable from "../components/User/UsersTable.vue";

export default {
  name: "UsersView",
  components: {
    UsersTable,
  },
};
</script>

<template>
  <companies-table></companies-table>
</template>

<script>
import CompaniesTable from "../components/Company/CompaniesTable.vue";

export default {
  name: "CompaniesView",
  components: {
    CompaniesTable,
  },
};
</script>

import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialogForestPlatformEdit),callback:function ($$v) {_vm.dialogForestPlatformEdit=$$v},expression:"dialogForestPlatformEdit"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Update Google Place CID")]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"cid","type":"text","rules":[_vm.requiredRule]},model:{value:(_vm.newCid),callback:function ($$v) {_vm.newCid=$$v},expression:"newCid"}})],1)],1),_c(VCardActions,[_c(VBtn,{on:{"click":_vm.closeDialog}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Confirm ")])],1)],1)],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialogConfirmation),callback:function ($$v) {_vm.dialogConfirmation=$$v},expression:"dialogConfirmation"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Warning! "),_c('br')]),_c(VCardText,[_vm._v(" Verify Google Map CID before updating! "),_c('br'),_c('br'),_c('b',[_c('a',{attrs:{"target":"_blank","href":'https://maps.google.com/maps?cid=' + _vm.newCid}},[_vm._v(" https://maps.google.com/maps?cid="+_vm._s(_vm.newCid)+" ")])])]),_c(VCardText,[(_vm.error)?_c(VAlert,{attrs:{"density":"compact","type":"error","title":"Error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":function($event){_vm.dialogConfirmation = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.updateForestPlatform}},[(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"20","width":"2","color":"white"}}):_vm._e(),_vm._v(" Confirm ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VNavigationDrawer,{attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('the-sidebar')],1),_c(VAppBar,{attrs:{"app":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_vm._v("ReviewForest")]),_c(VSpacer),_c('div',[_c('span',[_vm._v(_vm._s(_vm.getUserInfo.email))]),_c(VBtn,{staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","to":{ name: 'Logout' }}},[_c(VIcon,[_vm._v("logout")])],1)],1)],1),_c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{staticClass:"fill-height",attrs:{"justify":"center","align":"center"}},[_c(VCol,[_c(VCard,{staticClass:"pa-5 mx-auto",attrs:{"title":"SignIn","max-width":"480","width":"100%"}},[(_vm.status === _vm.$options.FORM_STATUS.ERROR)?_c(VAlert,{attrs:{"density":"compact","type":"error","title":"Error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c(VBtn,{attrs:{"block":""},on:{"click":_vm.signInUser}},[_vm._v("Sign in with Google")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
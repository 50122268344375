<template>
  <visitors-details></visitors-details>
</template>

<script>
import VisitorsDetails from "../components/Visitor/VisitorsDetails.vue";

export default {
  name: "VisitorsView",
  components: {
    VisitorsDetails,
  },
};
</script>

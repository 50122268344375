<template>
  <div class="chart-container">
    <Pie v-if="chartReady" :data="pieChartData" :options="chartOptions" />
    <div v-else>Loading chart...</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import { PROJECT_COLORS } from "../../constants";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default defineComponent({
  name: "PieChartProject",
  components: { Pie },
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      PROJECT_COLORS,
      chartReady: false,
      pieChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Distribution of Trees by Project",
            font: {
              size: 16,
            },
          },
        },
      },
    };
  },
  methods: {
    getColor(projectId) {
      return (
        PROJECT_COLORS[projectId] ||
        "#" + Math.floor(Math.random() * 16777215).toString(16)
      );
    },
    prepareChartData() {
      const platformTotals = this.chartData.reduce((acc, item) => {
        const projectId = item._id.projectId;
        if (!acc[projectId]) {
          acc[projectId] = 0;
        }
        acc[projectId] += item.reviewAmount;
        return acc;
      }, {});

      const labels = Object.keys(platformTotals);
      const data = Object.values(platformTotals);
      const backgroundColor = labels.map(this.getColor);

      this.pieChartData = {
        labels,
        datasets: [
          {
            backgroundColor,
            data,
          },
        ],
      };

      this.chartReady = true;
    },
  },
  watch: {
    chartData: {
      handler() {
        this.prepareChartData();
      },
      immediate: true,
      deep: true,
    },
  },
});
</script>

<style scoped>
.chart-container {
  height: 400px;
  width: 100%;
}
</style>

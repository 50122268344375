<template>
  <emails-table></emails-table>
</template>

<script>
import EmailsTable from "../components/Email/EmailsTable.vue";

export default {
  name: "EmailsView",
  components: {
    EmailsTable,
  },
};
</script>

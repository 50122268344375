<template>
  <v-card>
    <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
    <v-container fluid>
      <v-row dense>
        <v-col cols="3">
          <v-card-title>Crawl Requests</v-card-title>
          <v-card-subtitle>
            <DateRangePicker
              :ranges="ranges"
              v-model="dateRange"
              opens="right"
              :singleDatePicker="false"
              :maxDate="new Date()"
              @update="getChartData"
            >
              <template v-slot:input="picker">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </template>
            </DateRangePicker>
            <v-select
              v-model="selectedForests"
              :items="forests"
              item-value="id"
              item-text="name"
              label="Select Forests"
              multiple
              @change="getChartData"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span
                  v-if="index === 2"
                  class="text-grey text-caption align-self-center"
                >
                  (+{{ selectedForests.length - 2 }} others)
                </span>
              </template>
            </v-select>
          </v-card-subtitle>
        </v-col>
        <v-col cols="9">
          <template>
            <bar-chart :chartData="formattedChartData" />
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import moment from "moment";
import { API } from "../../api";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import BarChart from "../Charts/BarChart.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "CompanyCrawlRequests",
  components: {
    BarChart,
    DateRangePicker,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  filters: {
    date(date) {
      if (!date) return "";
      return date.toISOString().substr(0, 10).split("-").reverse().join(".");
    },
  },
  data() {
    return {
      dateRange: {
        startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      loaded: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      dataSet: [],
      totalCrawlRequests: 0,
      forests: [],
      selectedForests: [],
    };
  },
  async created() {
    await this.getForests();
    await this.getChartData();
  },
  computed: {
    formattedChartData() {
      return {
        labels: this.dataSet.map((item) => item.x),
        datasets: [
          {
            label: `Crawl Requests (${this.totalCrawlRequests})`,
            backgroundColor: "#5CB660",
            borderColor: "#5CB660",
            data: this.dataSet.map((item) => item.y),
          },
        ],
      };
    },
    ranges() {
      const startOfDay = new Date(new Date().setHours(2, 0, 0, 0));
      const endOfDay = new Date(new Date().setHours(23, 59, 59, 999));

      const thisMonthStart = new Date(
        startOfDay.getFullYear(),
        startOfDay.getMonth(),
        1
      );
      const thisMonthEnd = new Date(
        endOfDay.getFullYear(),
        endOfDay.getMonth() + 1,
        0
      );

      return {
        Today: [startOfDay, endOfDay],
        "Last 7 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 7
          ),
          endOfDay,
        ],
        "Last 30 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 30
          ),
          endOfDay,
        ],
        "This month": [thisMonthStart, thisMonthEnd],
        "This Year": [
          new Date(startOfDay.getFullYear(), 0, 1),
          new Date(endOfDay.getFullYear(), 11, 31),
        ],
        "Last month": [
          new Date(startOfDay.getFullYear(), startOfDay.getMonth() - 1, 1),
          new Date(endOfDay.getFullYear(), endOfDay.getMonth(), 0),
        ],
        "Last Year": [
          new Date(startOfDay.getFullYear() - 1, 0, 1),
          new Date(endOfDay.getFullYear() - 1, 11, 31),
        ],
      };
    },
  },
  methods: {
    formatChartData(chartData) {
      this.dataSet = [];

      if (
        moment(this.dateRange.startDate).format("YYYY-MM-DD") ===
        moment(this.dateRange.endDate).format("YYYY-MM-DD")
      ) {
        for (let index = 0; index < 24; index++) {
          const currentTotal =
            chartData.filter((el) => moment(el?.createdAt).hour() === index)
              ?.length ?? 0;

          this.dataSet.push({
            x: index,
            y: currentTotal,
          });
        }
      } else {
        const start = new Date(this.dateRange.startDate);
        start.setDate(start.getDate() - 1);
        const end = new Date(this.dateRange.endDate);

        for (
          const iterableDate = end;
          iterableDate.toISOString().substr(0, 10) !==
          start.toISOString().substr(0, 10);
          iterableDate.setDate(iterableDate.getDate() - 1)
        ) {
          const dateFormatted = iterableDate.toISOString().substr(0, 10);

          const currentTotal =
            chartData.filter(
              (el) =>
                moment(el?.createdAt).format("YYYY-MM-DD") === dateFormatted
            )?.length ?? 0;

          this.dataSet.push({
            x: dateFormatted,
            y: currentTotal,
          });
        }

        this.dataSet.reverse();
      }
    },
    async getForests() {
      this.loading = true;
      try {
        const { data } = await API.getForests({
          companyId: this.id,
          pageSize: 0,
        });

        this.forests = data.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );

        this.selectedForests =
          this.forests.length > 0 ? this.forests.map((f) => f.id) : [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getChartData() {
      this.loading = true;
      try {
        const visitorCriterias = {
          ownedBy: this.id,
          type: "crawlReview",
          pageSize: 0,
          forestId:
            this.selectedForests?.length > 0
              ? this.selectedForests.join(",")
              : null,
          ...this.dateRange,
        };

        const { data, count } = await API.getVisitors(visitorCriterias);

        this.totalCrawlRequests = count;

        this.formatChartData(data);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

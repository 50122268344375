<template>
  <v-card>
    <v-card-title>
      Profile
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-show="isNotGuest"
            v-on="on"
            class="ma-2"
            text
            icon
            color="primary"
            @click.prevent="loginAsCustomer(user.id)"
          >
            <v-icon>login</v-icon>
          </v-btn>
        </template>
        <span>Simulate User</span>
      </v-tooltip>
      <user-remove :email="user.email" :id="user.id" ref="updateRemoveModal">
      </user-remove>
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td style="width: 300px">Email</td>
            <td>
              <span ref="email">{{ user.email }}</span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="ma-2"
                    text
                    icon
                    v-if="user.email"
                    @click.prevent="copyUserEmail"
                  >
                    <img
                      svg-inline
                      src="../../assets/images/ico-copy.svg"
                      alt=""
                    />
                  </v-btn>
                </template>
                <span>Copy</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-show="isNotGuest"
                    v-on="on"
                    class="ma-2"
                    text
                    icon
                    color="primary"
                    @click="openUpdateEmailModal"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit Email</span>
              </v-tooltip>

              <user-update-email
                :email="user.email"
                :id="user.id"
                ref="updateEmailModal"
                @updateEmail="(email) => $emit('updateEmail', email)"
              >
              </user-update-email>
            </td>
          </tr>
          <tr>
            <td>ID</td>
            <td>
              <span ref="id">{{ user.id }}</span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="ma-2"
                    text
                    icon
                    v-if="user.id"
                    @click.prevent="copyUserId"
                  >
                    <img
                      svg-inline
                      src="../../assets/images/ico-copy.svg"
                      alt=""
                    />
                  </v-btn>
                </template>
                <span>Copy</span>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td>Joined at</td>
            <td>{{ formatDate(user.createdAt) }}</td>
          </tr>
          <tr>
            <td>Last login</td>
            <td>{{ formatDate(user.lastSignInTime) }}</td>
          </tr>
          <tr v-if="user.lastAdminSignIn">
            <td>Last admin login</td>
            <td>
              {{ formatDate(user.lastAdminSignIn.createdAt) }} |
              {{ user.lastAdminSignIn.email }}
            </td>
          </tr>
          <tr>
            <td>Role</td>
            <td>{{ user.role }}</td>
          </tr>
          <tr v-show="isNotGuest">
            <td>Email Verified</td>
            <td>
              <user-verify-email
                :user="user"
                :isDisabled="isNotGuest"
                @updateEmailVerification="
                  (val) => $emit('updateEmailVerification', val)
                "
              ></user-verify-email>
            </td>
          </tr>
          <tr>
            <td>Company</td>
            <td>
              <template v-if="selectedWorkspace">
                <v-row>
                  <v-col cols="3">
                    <v-select
                      :items="workspaces"
                      density="compact"
                      :value="value"
                      @input="(val) => $emit('input', val)"
                      item-text="companyId.name"
                      item-value="id"
                    ></v-select>
                  </v-col>
                  <v-col align-self="center">
                    <router-link
                      :to="{
                        name: 'Company',
                        params: { id: selectedWorkspace.companyId.id },
                      }"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            class="ma-2"
                            text
                            icon
                            color="primary"
                          >
                            <v-icon>link</v-icon>
                          </v-btn>
                        </template>
                        <span>Open company</span>
                      </v-tooltip>
                    </router-link>
                  </v-col>
                </v-row>
              </template>
              <template v-else>-</template>
            </td>
          </tr>
          <tr>
            <td>Permissions</td>
            <td>
              <template v-if="selectedWorkspace">
                {{ selectedWorkspace.permissions }}
              </template>
              <template v-else>-</template>
            </td>
          </tr>
          <tr v-if="!user.isRemoved && isNotGuest">
            <td>
              <v-btn
                color="error"
                text
                @click.stop="isActionDisabled = !isActionDisabled"
              >
                ADVANCED
              </v-btn>
            </td>
            <td>
              <v-btn
                v-if="!isActionDisabled"
                color="error"
                @click.prevent="openRemoveUserModal(user.id)"
              >
                REMOVE USER
              </v-btn>
            </td>
          </tr>
          <!-- <tr>
            <td>Subscription Plan</td>
            <td>
              <v-skeleton-loader
                v-if="loading"
                type="text"
                width="200px"
              ></v-skeleton-loader>
            </td>
          </tr> -->
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import UserRemove from "./UserRemove.vue";
import UserUpdateEmail from "./UserUpdateEmail.vue";
import UserVerifyEmail from "./UserVerifyEmail.vue";
import { loginAsCustomer, formatDate } from "../../helpers";
import copyText from "../../utils/copyText";

export default {
  name: "UserProfile",
  components: {
    UserRemove,
    UserUpdateEmail,
    UserVerifyEmail,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    workspaces: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    selectedWorkspace: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formatDate,
      loginAsCustomer,
      isActionDisabled: true,
    };
  },
  computed: {
    ...mapGetters(["isNotGuest"]),
  },
  methods: {
    openUpdateEmailModal() {
      this.$refs.updateEmailModal.dialogChangeEmail = true;
    },
    openRemoveUserModal() {
      this.$refs.updateRemoveModal.dialogRemoveUser = true;
    },
    copyUserEmail(e) {
      copyText({ target: this.$refs.email, trigger: e.currentTarget });
    },
    copyUserId(e) {
      copyText({ target: this.$refs.id, trigger: e.currentTarget });
    },
  },
};
</script>

<template>
  <span>
    <v-dialog v-model="dialogChangeEmail" max-width="600px">
      <v-card>
        <v-card-title>Edit Email</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="submitForm">
            <v-text-field
              v-model="editEmail"
              label="Email"
              type="email"
              :rules="emailRules"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" @click="submitForm"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmation" max-width="600px">
      <v-card>
        <v-card-title> Warning! <br /> </v-card-title>
        <v-card-text>
          Verify user identity before changing email, especially when altering
          the domain part. Security protocol must be followed!
          <br />
          <br />
          You are about to change the users login email to
          <b>{{ editEmail }}</b
          >.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogConfirmation = false">Cancel</v-btn>
          <v-btn color="primary" @click="changeEmail">
            <v-progress-circular
              v-if="loading"
              indeterminate
              size="20"
              width="2"
              color="white"
            ></v-progress-circular>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import { API } from "../../api";

export default {
  name: "UserUpdateEmail",
  props: {
    id: {
      type: String,
    },
    email: {
      type: String,
    },
  },
  data() {
    return {
      editEmail: "",
      dialogChangeEmail: false,
      dialogConfirmation: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      loading: false,
    };
  },
  watch: {
    dialogChangeEmail: {
      immediate: true,
      handler(open) {
        if (open) {
          this.editEmail = this.email;
        }
      },
    },
  },
  methods: {
    closeDialog() {
      this.dialogChangeEmail = false;
      this.dialogConfirmation = false;
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.dialogConfirmation = true;
      }
    },
    async changeEmail() {
      this.loading = true;
      try {
        const { data } = await API.updateUserEmailEdit({
          id: this.id,
          email: this.editEmail,
        });
        this.loading = false;
        this.closeDialog();
        this.$emit("updateEmail", data.email);
      } catch (error) {
        this.loading = false;
        return;
      }
    },
  },
};
</script>

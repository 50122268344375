<template>
  <v-card class="mx-auto">
    <v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
    <v-container fluid>
      <v-row dense>
        <v-col cols="3">
          <v-card-title>Visitors</v-card-title>
          <v-card-subtitle>
            <template>
              <DateRangePicker
                :ranges="ranges"
                v-model="dateRange"
                opens="right"
                :singleDatePicker="false"
                :maxDate="new Date()"
                @update="getChartData"
              >
                <template v-slot:input="picker">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
              </DateRangePicker>
              <v-select
                v-model="selectedVisitorLogType"
                :items="visitorLogTypes"
                item-value="id"
                item-text="name"
                label="Select Type"
                multiple
                @change="getChartData"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 2">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span
                    v-if="index === 2"
                    class="text-grey text-caption align-self-center"
                  >
                    (+{{ selectedVisitorLogType.length - 2 }} others)
                  </span>
                </template>
              </v-select>
            </template>
          </v-card-subtitle>
          <template v-if="mostCrawlRequestCompanies.length > 0">
            <v-card-text class="text-h6">
              5 Companies with Most Crawl Requests
            </v-card-text>
            <v-list-item
              v-for="item in mostCrawlRequestCompanies"
              :key="item.id"
              variant="contained"
            >
              <router-link :to="{ name: 'Company', params: { id: item.id } }">
                <v-list-item-title>
                  {{ shortenText(item.name, 25) }} ({{ item.count }})
                </v-list-item-title>
              </router-link>
            </v-list-item>
          </template>
          <v-spacer></v-spacer>
          <template v-if="mostCrawlRequestForests.length > 0">
            <v-card-text class="text-h6">
              5 Forests with Most Crawl Requests
            </v-card-text>
            <v-list-item
              v-for="item in mostCrawlRequestForests"
              :key="item.id"
              variant="contained"
            >
              <router-link :to="{ name: 'Forest', params: { id: item.id } }">
                <v-list-item-title>
                  {{ shortenText(item.name, 25) }} ({{ item.count }})
                </v-list-item-title>
              </router-link>
            </v-list-item>
          </template>
        </v-col>
        <v-col cols="9">
          <template>
            <bar-chart :chartData="formattedChartData" />
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import moment from "moment";
import { API } from "../../api";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import BarChart from "../Charts/BarChart.vue";
import { getMostCrawlRequest, shortenText } from "../../helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "VisitorsDetails",
  components: {
    BarChart,
    DateRangePicker,
  },
  data() {
    return {
      shortenText,
      dateRange: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      loaded: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      dataSet: [],
      totalCrawlRequests: 0,
      mostCrawlRequestCompanies: [],
      mostCrawlRequestForests: [],
      visitorLogTypes: [{ id: "crawlReview", name: "Crawl Requests" }],
      selectedVisitorLogType: ["crawlReview"],
    };
  },
  filters: {
    date(date) {
      if (!date) return "";
      return date.toISOString().substr(0, 10).split("-").reverse().join(".");
    },
  },
  created() {
    this.getChartData();
  },
  computed: {
    formattedChartData() {
      return {
        labels: this.dataSet.map((item) => item.x),
        datasets: [
          {
            label: `Crawl Requests (${this.totalCrawlRequests})`,
            backgroundColor: "#5CB660",
            borderColor: "#5CB660",
            data: this.dataSet.map((item) => item.y),
          },
        ],
      };
    },
    ranges() {
      const startOfDay = new Date(new Date().setHours(2, 0, 0, 0));
      const endOfDay = new Date(new Date().setHours(23, 59, 59, 999));

      const thisMonthStart = new Date(
        startOfDay.getFullYear(),
        startOfDay.getMonth(),
        1
      );
      const thisMonthEnd = new Date(
        endOfDay.getFullYear(),
        endOfDay.getMonth() + 1,
        0
      );

      return {
        Today: [startOfDay, endOfDay],
        "Last 7 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 7
          ),
          endOfDay,
        ],
        "Last 30 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 30
          ),
          endOfDay,
        ],
        "This month": [thisMonthStart, thisMonthEnd],
        "This Year": [
          new Date(startOfDay.getFullYear(), 0, 1),
          new Date(endOfDay.getFullYear(), 11, 31),
        ],
        "Last month": [
          new Date(startOfDay.getFullYear(), startOfDay.getMonth() - 1, 1),
          new Date(endOfDay.getFullYear(), endOfDay.getMonth(), 0),
        ],
        "Last Year": [
          new Date(startOfDay.getFullYear() - 1, 0, 1),
          new Date(endOfDay.getFullYear() - 1, 11, 31),
        ],
      };
    },
  },
  methods: {
    formatChartData(chartData) {
      this.dataSet = [];

      if (
        moment(this.dateRange.startDate).format("YYYY-MM-DD") ===
        moment(this.dateRange.endDate).format("YYYY-MM-DD")
      ) {
        for (let index = 0; index < 24; index++) {
          const currentTotal =
            chartData.filter((el) => moment(el?.createdAt).hour() === index)
              ?.length ?? 0;

          this.dataSet.push({
            x: index,
            y: currentTotal,
          });
        }
      } else {
        const start = new Date(this.dateRange.startDate);
        start.setDate(start.getDate() - 1);
        const end = new Date(this.dateRange.endDate);

        for (
          const iterableDate = end;
          iterableDate.toISOString().substr(0, 10) !==
          start.toISOString().substr(0, 10);
          iterableDate.setDate(iterableDate.getDate() - 1)
        ) {
          const dateFormatted = iterableDate.toISOString().substr(0, 10);

          const currentTotal =
            chartData.filter(
              (el) =>
                moment(el?.createdAt).format("YYYY-MM-DD") === dateFormatted
            )?.length ?? 0;

          this.dataSet.push({
            x: dateFormatted,
            y: currentTotal,
          });
        }

        this.dataSet.reverse();
      }
    },
    async getChartData() {
      this.loading = true;
      try {
        const { data, count } = await API.getVisitors({
          type:
            this.selectedVisitorLogType.length > 0
              ? this.selectedVisitorLogType.join()
              : null,
          pageSize: 0,
          ...this.dateRange,
        });

        this.totalCrawlRequests = count;

        this.mostCrawlRequestForests = getMostCrawlRequest(data, "forest");
        this.mostCrawlRequestCompanies = getMostCrawlRequest(data, "ownedBy");

        this.formatChartData(data);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

import { API } from "../api";
import moment from "moment";

export const getError = (error) => {
  if (error.code) {
    return error.code;
  }
  return error;
};

export const loginAsCustomer = async (id) => {
  const { token } = await API.getCustomToken(id);

  const url = new URL(process.env.VUE_APP_CLIENT_APP);
  url.search = new URLSearchParams({
    token,
  }).toString();

  window.open(url, "_blank").focus();
};

export const formatDate = (date, format = "MMMM D, YYYY HH:mm") => {
  if (!date) return;
  return moment(date).format(format);
};

export const formatDate2 = (date, format = "MMMM D, YYYY") => {
  if (!date) return;
  return moment(date).format(format);
};

export const getMostCrawlRequest = (crawlRequests, type) => {
  const groupByForestId = crawlRequests.reduce((result, currentItem) => {
    const dateKey = currentItem[type].id;

    if (!result[dateKey]) {
      result[dateKey] = [];
    }

    result[dateKey].push(currentItem);
    return result;
  }, {});

  const convertToArray = Object.entries(groupByForestId).map(
    ([id, elements]) => ({
      id,
      name: elements[0][type].name,
      count: elements.length,
    })
  );

  return convertToArray.sort((a, b) => b.count - a.count).slice(0, 5);
};

export const shortenText = (text, limit = 100) => {
  if (text.length > limit) {
    return text.substring(0, limit) + "...";
  }
  return text;
};

<template>
  <v-card>
    <v-card-title>
      Invoices
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="invoices"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalAmount"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.number`]="{ item }">
        <router-link
          :to="{
            name: 'InvoiceView',
            params: { id: item.id },
          }"
        >
          {{ item.number }}
        </router-link>
      </template>
      <template v-slot:[`item.data.amountDue`]="{ item }">
        {{ item.amountDue }} {{ item.currency }}
      </template>
      <template v-slot:[`item.links`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="ma-2"
              text
              icon
              color="primary"
              :href="`https://dashboard.stripe.com/invoices/${item.id}`"
              target="_blank"
            >
              <v-icon>link</v-icon>
            </v-btn>
          </template>
          <span>Stripe</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-if="item.id"
              class="ma-2"
              text
              icon
              color="primary"
              @click="downloadInvoice(item.id)"
            >
              <v-icon>picture_as_pdf</v-icon>
            </v-btn>
          </template>
          <span>PDF</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-if="item.breakdownPdf"
              class="ma-2"
              text
              icon
              color="primary"
              :href="item.breakdownPdf"
              target="_blank"
            >
              <v-icon>description</v-icon>
            </v-btn>
          </template>
          <span>Breakdown</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          v-if="item.paid && !item.fullRefunded"
          :to="{ name: 'InvoiceCreditNote', params: { id: item.id } }"
        >
          Issue Credit Note
        </router-link>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { API } from "../../api";
import { formatDate } from "../../helpers";

export default {
  name: "CompanyInvoices",
  props: {
    id: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      formatDate,
      loading: false,
      search: "",
      headers: [
        {
          text: "Number",
          value: "number",
          sortable: false,
          width: "150",
        },
        {
          text: "Type",
          value: "type",
          sortable: false,
        },
        {
          text: "Amount",
          align: "start",
          sortable: true,
          value: "data.amountDue",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Created At",
          sortable: true,
          value: "createdAt",
        },
        {
          text: "Description",
          sortable: false,
          value: "description",
        },
        {
          text: "Links",
          sortable: false,
          value: "links",
          width: "200",
        },
      ],
      invoices: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      totalAmount: 0,
    };
  },
  watch: {
    options: {
      handler: "debounceFetching",
      deep: true,
    },
    id: {
      immediate: true,
      handler: "debounceFetching",
    },
    search: {
      handler: "debounceFetching",
    },
  },
  computed: {
    searchQuery() {
      const trimmedSearch = this.search.trim();
      return trimmedSearch === "" ? null : trimmedSearch;
    },
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.getInvoices();
      }, 500);
    },
    async getInvoices() {
      if (this.id) {
        this.loading = true;
        this.invoices = [];
        try {
          const invoices = await API.getInvoices({
            companyId: this.id,
            sortBy: this.options.sortBy[0],
            order: this.options.sortDesc[0] ? "desc" : "asc",
            page: this.options.page,
            pageSize:
              this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage,
            query: this.searchQuery,
          });
          this.totalAmount = invoices.count;
          this.invoices = invoices.data.map((invoice) => {
            const newInvoice = {
              createdAt: new Date(invoice.created * 1000),
              currency: invoice.currency,
              description:
                invoice.priceDescription?.length < 100
                  ? invoice.priceDescription
                  : `${
                      invoice.type?.charAt(0).toUpperCase() +
                      invoice.type?.slice(1)
                    } Update`,
              total: invoice.total / 100 ?? 0,
              amountDue: invoice.amountDue / 100 ?? 0,
              status: invoice.status,
              pdf: invoice.invoicePdf,
              breakdownPdf: invoice.invoiceBreakdownPdf,
              number: invoice.invoiceNumber,
              id: invoice.stripeInvoiceId,
              type: invoice.type,
              quantity: invoice.quantity,
              refundable: invoice.refundable,
            };

            return newInvoice;
          });
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      }
    },
    async downloadInvoice(id) {
      this.loading = true;

      try {
        const pdfUrl = await API.getInvoicePdf(id);
        this.loading = false;
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

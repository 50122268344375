<template>
  <div>
    <v-breadcrumbs divider=">" :items="breadcrumbs" exact></v-breadcrumbs>
    <forest-crawl-requests :id="id"></forest-crawl-requests>
  </div>
</template>

<script>
import ForestCrawlRequests from "@/components/Forest/ForestCrawlRequests.vue";

export default {
  name: "ForestCrawlRequestsView",
  components: {
    ForestCrawlRequests,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Forests",
          to: { name: "Forests" },
          exact: true,
        },
        {
          text: this.name,
          to: {
            name: "Forest",
            params: { id: this.id },
          },
          exact: true,
        },
        {
          text: "Crawl Requests",
        },
      ];
    },
  },
};
</script>

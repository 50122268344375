<template>
  <div>
    <!-- Add submenu -->
    <div class="submenu mb-4">
      <button
        v-for="(item, index) in menuItems"
        :key="index"
        @click="activeView = item.value"
        :class="['menu-button', { active: activeView === item.value }]"
        :title="item.tooltip ? item.tooltip() : ''"
      >
        {{ typeof item.label === "function" ? item.label() : item.label }}
      </button>
    </div>

    <!-- Conditional rendering of components -->
    <company-profile v-if="activeView === 'profile'" :id="id" class="mb-10" />
    <company-forests
      v-if="activeView === 'forests'"
      :companyId="id"
      class="mb-10"
    />
    <company-billing-info
      v-if="activeView === 'billing'"
      :id="id"
      class="mb-10"
    />
    <company-invoices v-if="activeView === 'invoices'" :id="id" class="mb-10" />
    <company-emails v-if="activeView === 'emails'" :id="id" class="mb-10" />
    <company-tree-planting
      v-if="activeView === 'tree-planting'"
      :id="id"
      class="mb-10"
    />
  </div>
</template>

<script>
import { API } from "../api";

import CompanyProfile from "../components/Company/CompanyProfile.vue";
import CompanyForests from "../components/Company/CompanyForests.vue";
import CompanyBillingInfo from "../components/Company/CompanyBillingInfo.vue";
import CompanyInvoices from "../components/Company/CompanyInvoices.vue";
import CompanyEmails from "../components/Company/CompanyEmails.vue";
import CompanyTreePlanting from "../components/Company/CompanyTreePlanting.vue";
export default {
  name: "CompanyView",
  components: {
    CompanyProfile,
    CompanyForests,
    CompanyBillingInfo,
    CompanyInvoices,
    CompanyEmails,
    CompanyTreePlanting,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeView: "profile",
      companyName: "", // Add this line
      menuItems: [
        {
          label: () => {
            const maxLength = 10; // Adjust this value as needed
            const name = this.companyName || "Company Profile";
            return name.length > maxLength
              ? name.substring(0, maxLength) + "..."
              : name;
          },
          value: "profile",
          tooltip: () => this.companyName,
        },
        { label: "Forests", value: "forests" },
        { label: "Billing", value: "billing" },
        { label: "Invoices", value: "invoices" },
        { label: "Emails", value: "emails" },
        { label: "Trees", value: "tree-planting" },
      ],
    };
  },
  async created() {
    try {
      const response = await API.getCompany({ companyId: this.id });
      this.companyName = response.name;
    } catch (error) {
      console.error("Failed to fetch company name:", error);
    }
  },
};
</script>

<style scoped>
.submenu {
  display: flex;
  gap: 1rem;
}

.menu-button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  width: 150px;
}

.menu-button.active {
  background: rgb(226, 225, 225);
  color: black;
  border-color: rgb(226, 225, 225);
}
</style>

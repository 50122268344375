<template>
  <v-card>
    <v-card-title>Forest</v-card-title>
    <v-simple-table>
      <tbody>
        <tr>
          <td style="width: 300px">ID</td>
          <td>
            <span ref="id">{{ forest.id }}</span>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="ma-2"
                  text
                  icon
                  v-if="forest.id"
                  @click.prevent="copyLink"
                >
                  <img
                    svg-inline
                    src="../../assets/images/ico-copy.svg"
                    alt=""
                  />
                </v-btn>
              </template>
              <span>Copy</span>
            </v-tooltip>
          </td>
        </tr>
        <tr>
          <td>Name</td>
          <td>{{ forest.name }}</td>
        </tr>
        <tr>
          <td>URL</td>
          <td>
            <a
              :href="`${$options.FOREST_DOMAIN}/${forest.slug}`"
              target="_blank"
              >{{ `${$options.FOREST_DOMAIN}/${forest.slug}` }}</a
            >
          </td>
        </tr>
        <tr>
          <td>Company</td>
          <td>
            <router-link
              :to="{ name: 'Company', params: { id: forest.ownedBy } }"
              ><span ref="companyId">{{ forest.ownedBy }}</span></router-link
            >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="ma-2"
                  text
                  icon
                  v-if="forest.ownedBy"
                  @click.prevent="copyCompanyId"
                >
                  <img
                    svg-inline
                    src="../../assets/images/ico-copy.svg"
                    alt=""
                  />
                </v-btn>
              </template>
              <span>Copy</span>
            </v-tooltip>
          </td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{{ forest.type }}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{{ forest.active ? "Active" : "Deactivated" }}</td>
        </tr>
        <tr>
          <td>Created</td>
          <td>{{ forest.createdAt }}</td>
        </tr>
        <template v-if="forest.type === $options.FOREST_TYPES.SINGLE">
          <tr>
            <td>Platforms order</td>
            <td>
              <platforms-logo
                :platforms="forest.platformsOrder"
              ></platforms-logo>
            </td>
          </tr>
          <tr>
            <td>Pinned platform</td>
            <td>
              <platform-logo
                v-if="forest.pinnedPlatform"
                :platform="forest.pinnedPlatform"
              ></platform-logo>
              <template v-else>-</template>
            </td>
          </tr>
          <tr>
            <td>Tree limit</td>
            <td>
              {{
                forest.plantingReviewLimit === -1
                  ? "Unlimited"
                  : forest.plantingReviewLimit
              }}
            </td>
          </tr>
          <tr>
            <td>Planting Project</td>
            <td>{{ $options.PLANTING_PROJECTS[forest.projectId] }}</td>
          </tr>
        </template>
        <tr>
          <td>Review amount</td>
          <td>{{ forest.reviewAmount }}</td>
        </tr>
        <tr>
          <td>Planted trees</td>
          <td>
            <v-simple-table>
              <tbody>
                <tr>
                  <td style="width: 150px">For reviews</td>
                  <td>{{ forest.reviewTreeAmount }}</td>
                </tr>
                <tr>
                  <td>Additional trees</td>
                  <td>{{ forest.additionalTreeAmount }}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{{ forest.totalTreeAmount }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </tr>
        <tr>
          <td>Rating</td>
          <td>{{ forest.score }}</td>
        </tr>
        <tr>
          <td>Crawl requests</td>
          <td>
            <router-link
              :to="{
                name: 'ForestCrawlRequests',
                params: { id: forest.id, name: forest.name },
              }"
              >{{ forestCrawlRequests }}</router-link
            >
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { PLANTING_PROJECTS, FOREST_TYPES } from "../../constants";
import PlatformLogo from "../Base/PlatformLogo.vue";
import PlatformsLogo from "../Base/PlatformsLogo.vue";
import copyText from "../../utils/copyText";

export default {
  name: "ForestProfile",
  FOREST_DOMAIN: process.env.VUE_APP_FOREST_DOMAIN,
  PLANTING_PROJECTS,
  FOREST_TYPES,
  components: {
    PlatformsLogo,
    PlatformLogo,
  },
  props: {
    forest: {
      type: Object,
      required: true,
    },
    forestCrawlRequests: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    copyLink(e) {
      copyText({ target: this.$refs.id, trigger: e.currentTarget });
    },
    copyCompanyId(e) {
      copyText({ target: this.$refs.companyId, trigger: e.currentTarget });
    },
  },
};
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Profile "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.isNotGuest),expression:"isNotGuest"}],staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.loginAsCustomer(_vm.user.id)}}},on),[_c(VIcon,[_vm._v("login")])],1)]}}])},[_c('span',[_vm._v("Simulate User")])]),_c('user-remove',{ref:"updateRemoveModal",attrs:{"email":_vm.user.email,"id":_vm.user.id}})],1),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"300px"}},[_vm._v("Email")]),_c('td',[_c('span',{ref:"email"},[_vm._v(_vm._s(_vm.user.email))]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.user.email)?_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.copyUserEmail.apply(null, arguments)}}},on),[_c('img',{attrs:{"svg-inline":"","src":require("../../assets/images/ico-copy.svg"),"alt":""}})]):_vm._e()]}}])},[_c('span',[_vm._v("Copy")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.isNotGuest),expression:"isNotGuest"}],staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary"},on:{"click":_vm.openUpdateEmailModal}},on),[_c(VIcon,[_vm._v("edit")])],1)]}}])},[_c('span',[_vm._v("Edit Email")])]),_c('user-update-email',{ref:"updateEmailModal",attrs:{"email":_vm.user.email,"id":_vm.user.id},on:{"updateEmail":function (email) { return _vm.$emit('updateEmail', email); }}})],1)]),_c('tr',[_c('td',[_vm._v("ID")]),_c('td',[_c('span',{ref:"id"},[_vm._v(_vm._s(_vm.user.id))]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.user.id)?_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.copyUserId.apply(null, arguments)}}},on),[_c('img',{attrs:{"svg-inline":"","src":require("../../assets/images/ico-copy.svg"),"alt":""}})]):_vm._e()]}}])},[_c('span',[_vm._v("Copy")])])],1)]),_c('tr',[_c('td',[_vm._v("Joined at")]),_c('td',[_vm._v(_vm._s(_vm.formatDate(_vm.user.createdAt)))])]),_c('tr',[_c('td',[_vm._v("Last login")]),_c('td',[_vm._v(_vm._s(_vm.formatDate(_vm.user.lastSignInTime)))])]),(_vm.user.lastAdminSignIn)?_c('tr',[_c('td',[_vm._v("Last admin login")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.user.lastAdminSignIn.createdAt))+" | "+_vm._s(_vm.user.lastAdminSignIn.email)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Role")]),_c('td',[_vm._v(_vm._s(_vm.user.role))])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNotGuest),expression:"isNotGuest"}]},[_c('td',[_vm._v("Email Verified")]),_c('td',[_c('user-verify-email',{attrs:{"user":_vm.user,"isDisabled":_vm.isNotGuest},on:{"updateEmailVerification":function (val) { return _vm.$emit('updateEmailVerification', val); }}})],1)]),_c('tr',[_c('td',[_vm._v("Company")]),_c('td',[(_vm.selectedWorkspace)?[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"items":_vm.workspaces,"density":"compact","value":_vm.value,"item-text":"companyId.name","item-value":"id"},on:{"input":function (val) { return _vm.$emit('input', val); }}})],1),_c(VCol,{attrs:{"align-self":"center"}},[_c('router-link',{attrs:{"to":{
                      name: 'Company',
                      params: { id: _vm.selectedWorkspace.companyId.id },
                    }}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary"}},on),[_c(VIcon,[_vm._v("link")])],1)]}}],null,false,2877825653)},[_c('span',[_vm._v("Open company")])])],1)],1)],1)]:[_vm._v("-")]],2)]),_c('tr',[_c('td',[_vm._v("Permissions")]),_c('td',[(_vm.selectedWorkspace)?[_vm._v(" "+_vm._s(_vm.selectedWorkspace.permissions)+" ")]:[_vm._v("-")]],2)]),(!_vm.user.isRemoved && _vm.isNotGuest)?_c('tr',[_c('td',[_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){$event.stopPropagation();_vm.isActionDisabled = !_vm.isActionDisabled}}},[_vm._v(" ADVANCED ")])],1),_c('td',[(!_vm.isActionDisabled)?_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.openRemoveUserModal(_vm.user.id)}}},[_vm._v(" REMOVE USER ")]):_vm._e()],1)]):_vm._e()])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <the-sidebar></the-sidebar>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>ReviewForest</v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <span>{{ getUserInfo.email }}</span>
        <v-btn class="ma-2" text icon color="primary" :to="{ name: 'Logout' }">
          <v-icon>logout</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TheSidebar from "../components/TheSidebar.vue";

export default {
  name: "SidebarLayout",
  components: {
    TheSidebar,
  },
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
};
</script>

const countryList = [
  {
    code: "AF",
    code3: "AFG",
    name: "Afghanistan",
    "name-de": "Afghanistan",
    insideEU: false,
    languages: ["ps", "uz", "tk"],
  },
  {
    code: "AL",
    code3: "ALB",
    name: "Albania",
    "name-de": "Albanien",
    insideEU: false,
    languages: ["sq"],
  },
  {
    code: "DZ",
    code3: "DZA",
    name: "Algeria",
    "name-de": "Algerien",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "AS",
    code3: "ASM",
    name: "American Samoa",
    "name-de": "Amerikanisch-Samoa",
    insideEU: false,
    languages: ["sm", "en"],
  },
  {
    code: "AD",
    code3: "AND",
    name: "Andorra",
    "name-de": "Andorra",
    insideEU: false,
    languages: ["ca"],
  },
  {
    code: "AO",
    code3: "AGO",
    name: "Angola",
    "name-de": "Angola",
    insideEU: false,
    languages: ["pt"],
  },
  {
    code: "AI",
    code3: "AIA",
    name: "Anguilla",
    "name-de": "Anguilla",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "AQ",
    code3: "ATA",
    name: "Antarctica",
    "name-de": "Antarktis",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "AG",
    code3: "ATG",
    name: "Antigua and Barbuda",
    "name-de": "Antigua und Barbuda",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "AR",
    code3: "ARG",
    name: "Argentina",
    "name-de": "Argentinien",
    insideEU: false,
    languages: ["es", "gn"],
  },
  {
    code: "AM",
    code3: "ARM",
    name: "Armenia",
    "name-de": "Armenien",
    insideEU: false,
    languages: ["hy", "ru"],
  },
  {
    code: "AW",
    code3: "ABW",
    name: "Aruba",
    "name-de": "Aruba",
    insideEU: false,
    languages: ["nl", "pa"],
  },
  {
    code: "AU",
    code3: "AUS",
    name: "Australia",
    "name-de": "Australien",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "AT",
    code3: "AUT",
    name: "Austria",
    "name-de": "Österreich",
    insideEU: true,
    languages: ["de"],
    vatPrefix: "AT",
    vatExample: "U13585627",
  },
  {
    code: "AZ",
    code3: "AZE",
    name: "Azerbaijan",
    "name-de": "Aserbaidschan",
    insideEU: false,
    languages: ["az"],
  },
  {
    code: "BS",
    code3: "BHS",
    name: "Bahamas",
    "name-de": "Bahamas",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "BH",
    code3: "BHR",
    name: "Bahrain",
    "name-de": "Bahrain",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "BD",
    code3: "BGD",
    name: "Bangladesh",
    "name-de": "Bangladesch",
    insideEU: false,
    languages: ["bn"],
  },
  {
    code: "BB",
    code3: "BRB",
    name: "Barbados",
    "name-de": "Barbados",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "BY",
    code3: "BLR",
    name: "Belarus",
    "name-de": "Weißrussland",
    insideEU: false,
    languages: ["be", "ru"],
  },
  {
    code: "BE",
    code3: "BEL",
    name: "Belgium",
    "name-de": "Belgien",
    insideEU: true,
    languages: ["nl", "fr", "de"],
    vatPrefix: "BE",
    vatExample: "431150351",
  },
  {
    code: "BZ",
    code3: "BLZ",
    name: "Belize",
    "name-de": "Belize",
    insideEU: false,
    languages: ["en", "es"],
  },
  {
    code: "BJ",
    code3: "BEN",
    name: "Benin",
    "name-de": "Benin",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "BM",
    code3: "BMU",
    name: "Bermuda",
    "name-de": "Bermuda",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "BT",
    code3: "BTN",
    name: "Bhutan",
    "name-de": "Bhutan",
    insideEU: false,
    languages: ["dz"],
  },
  {
    code: "BO",
    code3: "BOL",
    name: "Bolivia",
    "name-de": "Bolivien",
    insideEU: false,
    languages: ["es", "ay", "qu"],
  },
  {
    code: "BQ",
    code3: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    "name-de": "Bonaire",
    insideEU: false,
    languages: ["nl"],
  },
  {
    code: "BA",
    code3: "BIH",
    name: "Bosnia and Herzegovina",
    "name-de": "Bosnien und Herzegowina",
    insideEU: false,
    languages: ["bs", "hr", "sr"],
  },
  {
    code: "BW",
    code3: "BWA",
    name: "Botswana",
    "name-de": "Botsuana (Botswana)",
    insideEU: false,
    languages: ["en", "tn"],
  },
  {
    code: "BV",
    code3: "BVT",
    name: "Bouvet Island",
    "name-de": "Bouvetinsel",
    insideEU: false,
    languages: ["no", "nb", "nn"],
  },
  {
    code: "BR",
    code3: "BRA",
    name: "Brazil",
    "name-de": "Brasilien",
    insideEU: false,
    languages: ["pt"],
  },
  {
    code: "IO",
    code3: "IOT",
    name: "British Indian Ocean Territory",
    "name-de": "Britisches Territorium im Indischen Ozean	",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "BN",
    code3: "BRN",
    name: "Brunei Darussalam",
    "name-de": "Brunei",
    insideEU: false,
    languages: ["ms"],
  },
  {
    code: "BG",
    code3: "BGR",
    name: "Bulgaria",
    "name-de": "Bulgarien",
    insideEU: true,
    languages: ["bg"],
    vatPrefix: "BG",
    vatExample: "175074752",
  },
  {
    code: "BF",
    code3: "BFA",
    name: "Burkina Faso",
    "name-de": "Burkina Faso",
    insideEU: false,
    languages: ["fr", "ff"],
  },
  {
    code: "BI",
    code3: "BDI",
    name: "Burundi",
    "name-de": "Burundi",
    insideEU: false,
    languages: ["fr", "rn"],
  },
  {
    code: "KH",
    code3: "KHM",
    name: "Cambodia",
    "name-de": "Kambodscha",
    insideEU: false,
    languages: ["km"],
  },
  {
    code: "CM",
    code3: "CMR",
    name: "Cameroon",
    "name-de": "Kamerun",
    insideEU: false,
    languages: ["en", "fr"],
  },
  {
    code: "CA",
    code3: "CAN",
    name: "Canada",
    "name-de": "Kanada",
    insideEU: false,
    languages: ["en", "fr"],
  },
  {
    code: "CV",
    code3: "CPV",
    name: "Cape Verde",
    "name-de": "Kap Verde",
    insideEU: false,
    languages: ["pt"],
  },
  {
    code: "KY",
    code3: "CYM",
    name: "Cayman Islands",
    "name-de": "Caymaninseln, auch Kaimaninseln",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "CF",
    code3: "CAF",
    name: "Central African Republic",
    "name-de": "Zentralafrikanische Republik",
    insideEU: false,
    languages: ["fr", "sg"],
  },
  {
    code: "TD",
    code3: "TCD",
    name: "Chad",
    "name-de": "Tschad",
    insideEU: false,
    languages: ["fr", "ar"],
  },
  {
    code: "CL",
    code3: "CHL",
    name: "Chile",
    "name-de": "Chile",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "CN",
    code3: "CHN",
    name: "China",
    "name-de": "China, Volksrepublik China",
    insideEU: false,
    languages: ["zh"],
  },
  {
    code: "CX",
    code3: "CXR",
    name: "Christmas Island",
    "name-de": "Weihnachtsinsel",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "CC",
    code3: "CCK",
    name: "Cocos (Keeling) Islands",
    "name-de": "Kokosinseln, Keelinginseln",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "CO",
    code3: "COL",
    name: "Colombia",
    "name-de": "Kolumbien",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "KM",
    code3: "COM",
    name: "Comoros",
    "name-de": "Komoren",
    insideEU: false,
    languages: ["ar", "fr"],
  },
  {
    code: "CD",
    code3: "COD",
    name: "Democratic Republic of the Congo",
    "name-de": "Demokratische Republik Kongo",
    insideEU: false,
    languages: ["fr", "ln", "kg", "sw", "lu"],
  },
  {
    code: "CG",
    code3: "COG",
    name: "Congo",
    "name-de": "Republik Kongo",
    insideEU: false,
    languages: ["fr", "ln"],
  },
  {
    code: "CK",
    code3: "COK",
    name: "Cook Islands",
    "name-de": "Cookinseln",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "CR",
    code3: "CRI",
    name: "Costa Rica",
    "name-de": "Costa Rica",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "HR",
    code3: "HRV",
    name: "Croatia",
    "name-de": "Kroatien",
    insideEU: true,
    languages: ["hr"],
    vatPrefix: "HR",
    vatExample: "33392005961",
  },
  {
    code: "CU",
    code3: "CUB",
    name: "Cuba",
    "name-de": "Kuba",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "CW",
    code3: "CUW",
    name: "Curaçao",
    "name-de": "Curaçao",
    insideEU: false,
    languages: ["nl", "pa", "en"],
  },
  {
    code: "CY",
    code3: "CYP",
    name: "Cyprus",
    "name-de": "Zypern",
    insideEU: true,
    languages: ["el", "tr", "hy"],
    vatPrefix: "CY",
    vatExample: "10259033P",
  },
  {
    code: "CZ",
    code3: "CZE",
    name: "Czech Republic",
    "name-de": "Tschechien",
    insideEU: true,
    languages: ["cs", "sk"],
    vatPrefix: "CZ",
    vatExample: "7103192745",
  },
  {
    code: "CI",
    code3: "CIV",
    name: "Ivory Coast (Cote d'Ivoire)",
    "name-de": "Elfenbeinküste",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "DK",
    code3: "DNK",
    name: "Denmark",
    "name-de": "Dänemark",
    insideEU: true,
    languages: ["da"],
    vatPrefix: "DK",
    vatExample: "13585628",
  },
  {
    code: "DJ",
    code3: "DJI",
    name: "Djibouti",
    "name-de": "Dschibuti",
    insideEU: false,
    languages: ["fr", "ar"],
  },
  {
    code: "DM",
    code3: "DMA",
    name: "Dominica",
    "name-de": "Dominica",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "DO",
    code3: "DOM",
    name: "Dominican Republic",
    "name-de": "Dominikanische Republik (Dom Rep)",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "EC",
    code3: "ECU",
    name: "Ecuador",
    "name-de": "Ecuador",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "EG",
    code3: "EGY",
    name: "Egypt",
    "name-de": "Ägypten",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "SV",
    code3: "SLV",
    name: "El Salvador",
    "name-de": "El Salvador",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "GQ",
    code3: "GNQ",
    name: "Equatorial Guinea",
    "name-de": "Äquatorialguinea",
    insideEU: false,
    languages: ["es", "fr"],
  },
  {
    code: "ER",
    code3: "ERI",
    name: "Eritrea",
    "name-de": "Eritrea",
    insideEU: false,
    languages: ["ti", "ar", "en"],
  },
  {
    code: "EE",
    code3: "EST",
    name: "Estonia",
    "name-de": "Estland",
    insideEU: true,
    languages: ["et"],
    vatPrefix: "EE",
    vatExample: "100931558",
  },
  {
    code: "SZ",
    code3: "SWZ",
    name: "Eswatini",
    "name-de": "Swasiland",
    insideEU: false,
    languages: ["en", "ss"],
  },
  {
    code: "ET",
    code3: "ETH",
    name: "Ethiopia",
    "name-de": "Äthiopien",
    insideEU: false,
    languages: ["am"],
  },
  {
    code: "FK",
    code3: "FLK",
    name: "Falkland Islands",
    "name-de": "Falklandinseln",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "FO",
    code3: "FRO",
    name: "Faroe Islands",
    "name-de": "Färöer (Färöer-Inseln)",
    insideEU: false,
    languages: ["fo"],
  },
  {
    code: "FJ",
    code3: "FJI",
    name: "Fiji",
    "name-de": "Fidschi",
    insideEU: false,
    languages: ["en", "fj", "hi", "ur"],
  },
  {
    code: "FI",
    code3: "FIN",
    name: "Finland",
    "name-de": "Finnland",
    insideEU: true,
    languages: ["fi", "sv"],
    vatPrefix: "FI",
    vatExample: "20774741",
  },
  {
    code: "FR",
    code3: "FRA",
    name: "France",
    "name-de": "Frankreich",
    insideEU: true,
    languages: ["fr"],
    vatPrefix: "FR",
    vatExample: "K7399859412",
  },
  {
    code: "GF",
    code3: "GUF",
    name: "French Guiana",
    "name-de": "Französisch-Guayana",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "PF",
    code3: "PYF",
    name: "French Polynesia",
    "name-de": "Französisch-Polynesien",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "TF",
    code3: "ATF",
    name: "French Southern Territories",
    "name-de": "Französische Süd- und Antarktisgebiete",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "GA",
    code3: "GAB",
    name: "Gabon",
    "name-de": "Gabun",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "GM",
    code3: "GMB",
    name: "Gambia",
    "name-de": "Gambia",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "GE",
    code3: "GEO",
    name: "Georgia",
    "name-de": "Georgien",
    insideEU: false,
    languages: ["ka"],
  },
  {
    code: "DE",
    code3: "DEU",
    name: "Germany",
    "name-de": "Deutschland",
    insideEU: true,
    languages: ["de"],
    vatPrefix: "DE",
    vatExample: "136695976",
  },
  {
    code: "GH",
    code3: "GHA",
    name: "Ghana",
    "name-de": "Ghana",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "GI",
    code3: "GIB",
    name: "Gibraltar",
    "name-de": "Gibraltar",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "GR",
    code3: "GRC",
    name: "Greece",
    "name-de": "Griechenland",
    insideEU: true,
    languages: ["el"],
    vatPrefix: "GR",
    vatExample: "094259216",
  },
  {
    code: "GL",
    code3: "GRL",
    name: "Greenland",
    "name-de": "Grönland",
    insideEU: false,
    languages: ["kl"],
  },
  {
    code: "GD",
    code3: "GRD",
    name: "Grenada",
    "name-de": "Grenada",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "GP",
    code3: "GLP",
    name: "Guadeloupe",
    "name-de": "Guadeloupe",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "GU",
    code3: "GUM",
    name: "Guam",
    "name-de": "Guam",
    insideEU: false,
    languages: ["en", "ch", "es"],
  },
  {
    code: "GT",
    code3: "GTM",
    name: "Guatemala",
    "name-de": "Guatemala",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "GG",
    code3: "GGY",
    name: "Guernsey",
    "name-de": "Guernsey",
    insideEU: false,
    languages: ["en", "fr"],
  },
  {
    code: "GN",
    code3: "GIN",
    name: "Guinea",
    "name-de": "Guinea",
    insideEU: false,
    languages: ["fr", "ff"],
  },
  {
    code: "GW",
    code3: "GNB",
    name: "Guinea-Bissau",
    "name-de": "Guinea-Bissau	",
    insideEU: false,
    languages: ["pt"],
  },
  {
    code: "GY",
    code3: "GUY",
    name: "Guyana",
    "name-de": "Guyana",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "HT",
    code3: "HTI",
    name: "Haiti",
    "name-de": "Haiti",
    insideEU: false,
    languages: ["fr", "ht"],
  },
  {
    code: "HM",
    code3: "HMD",
    name: "Heard Island and McDonald Islands",
    "name-de": "Heard und McDonaldinseln",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "VA",
    code3: "VAT",
    name: "Vatican City",
    "name-de": "Vatikanstadt",
    insideEU: false,
    languages: ["it", "la"],
  },
  {
    code: "HN",
    code3: "HND",
    name: "Honduras",
    "name-de": "Honduras",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "HK",
    code3: "HKG",
    name: "Hong Kong",
    "name-de": "Hong Kong",
    insideEU: false,
    languages: ["zh", "en"],
  },
  {
    code: "HU",
    code3: "HUN",
    name: "Hungary",
    "name-de": "Ungarn",
    insideEU: true,
    languages: ["hu"],
    vatPrefix: "HU",
    vatExample: "12892312",
  },
  {
    code: "IS",
    code3: "ISL",
    name: "Iceland",
    "name-de": "Island",
    insideEU: false,
    languages: ["is"],
  },
  {
    code: "IN",
    code3: "IND",
    name: "India",
    "name-de": "Indien",
    insideEU: false,
    languages: ["hi", "en"],
  },
  {
    code: "ID",
    code3: "IDN",
    name: "Indonesia",
    "name-de": "Indonesien",
    insideEU: false,
    languages: ["id"],
  },
  {
    code: "IR",
    code3: "IRN",
    name: "Iran (Islamic Republic of)",
    "name-de": "Iran (Persien)",
    insideEU: false,
    languages: ["fa"],
  },
  {
    code: "IQ",
    code3: "IRQ",
    name: "Iraq",
    "name-de": "Irak",
    insideEU: false,
    languages: ["ar", "ku"],
  },
  {
    code: "IE",
    code3: "IRL",
    name: "Ireland",
    "name-de": "Irland",
    insideEU: true,
    languages: ["ga", "en"],
    vatPrefix: "IE",
    vatExample: "8D79739I",
  },
  {
    code: "IM",
    code3: "IMN",
    name: "Isle of Man",
    "name-de": "Insel Man",
    insideEU: false,
    languages: ["en", "gv"],
  },
  {
    code: "IL",
    code3: "ISR",
    name: "Israel",
    "name-de": "Israel",
    insideEU: false,
    languages: ["he", "ar"],
  },
  {
    code: "IT",
    code3: "ITA",
    name: "Italy",
    "name-de": "Italien",
    insideEU: true,
    languages: ["it"],
    vatPrefix: "IT",
    vatExample: "00743110157",
  },
  {
    code: "JM",
    code3: "JAM",
    name: "Jamaica",
    "name-de": "Jamaica",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "JP",
    code3: "JPN",
    name: "Japan",
    "name-de": "Japan",
    insideEU: false,
    languages: ["ja"],
  },
  {
    code: "JE",
    code3: "JEY",
    name: "Jersey",
    "name-de": "Jersey",
    insideEU: false,
    languages: ["en", "fr"],
  },
  {
    code: "JO",
    code3: "JOR",
    name: "Jordan",
    "name-de": "Jordanien",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "KZ",
    code3: "KAZ",
    name: "Kazakhstan",
    "name-de": "Kasachstan",
    insideEU: false,
    languages: ["kk", "ru"],
  },
  {
    code: "KE",
    code3: "KEN",
    name: "Kenya",
    "name-de": "Kenia",
    insideEU: false,
    languages: ["en", "sw"],
  },
  {
    code: "KI",
    code3: "KIR",
    name: "Kiribati",
    "name-de": "Kiribati",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "KP",
    code3: "PRK",
    name: "Democratic People's Republic of Korea",
    "name-de": "Nordkorea",
    insideEU: false,
    languages: ["ko"],
  },
  {
    code: "KR",
    code3: "KOR",
    name: "Republic of Korea (South Korea)",
    "name-de": "Südkorea (Republik Korea)	",
    insideEU: false,
    languages: ["ko"],
  },
  {
    code: "KW",
    code3: "KWT",
    name: "Kuwait",
    "name-de": "Kuwait",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "KG",
    code3: "KGZ",
    name: "Kyrgyzstan",
    "name-de": "Kirgisistan",
    insideEU: false,
    languages: ["ky", "ru"],
  },
  {
    code: "LA",
    code3: "LAO",
    name: "Lao People's Democratic Republic",
    "name-de": "Laos",
    insideEU: false,
    languages: ["lo"],
  },
  {
    code: "LV",
    code3: "LVA",
    name: "Latvia",
    "name-de": "Lettland",
    insideEU: true,
    languages: ["lv"],
    vatPrefix: "LV",
    vatExample: "16137519997",
  },
  {
    code: "LB",
    code3: "LBN",
    name: "Lebanon",
    "name-de": "Libanon",
    insideEU: false,
    languages: ["ar", "fr"],
  },
  {
    code: "LS",
    code3: "LSO",
    name: "Lesotho",
    "name-de": "Lesotho",
    insideEU: false,
    languages: ["en", "st"],
  },
  {
    code: "LR",
    code3: "LBR",
    name: "Liberia",
    "name-de": "Liberia",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "LY",
    code3: "LBY",
    name: "Libya",
    "name-de": "Libyen",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "LI",
    code3: "LIE",
    name: "Liechtenstein",
    "name-de": "Liechtenstein",
    insideEU: false,
    languages: ["de"],
  },
  {
    code: "LT",
    code3: "LTU",
    name: "Lithuania",
    "name-de": "Litauen",
    insideEU: true,
    languages: ["lt"],
    vatPrefix: "LT",
    vatExample: "100004801610",
  },
  {
    code: "LU",
    code3: "LUX",
    name: "Luxembourg",
    "name-de": "Luxemburg",
    insideEU: true,
    languages: ["fr", "de", "lb"],
    vatPrefix: "LU",
    vatExample: "15027443",
  },
  {
    code: "MO",
    code3: "MAC",
    name: "Macau",
    "name-de": "Macau",
    insideEU: false,
    languages: ["zh", "pt"],
  },
  {
    code: "MG",
    code3: "MDG",
    name: "Madagascar",
    "name-de": "Madagaskar",
    insideEU: false,
    languages: ["fr", "mg"],
  },
  {
    code: "MW",
    code3: "MWI",
    name: "Malawi",
    "name-de": "Malawi",
    insideEU: false,
    languages: ["en", "ny"],
  },
  {
    code: "MY",
    code3: "MYS",
    name: "Malaysia",
    "name-de": "Malaysia",
    insideEU: false,
    languages: ["ms"],
  },
  {
    code: "MV",
    code3: "MDV",
    name: "Maldives",
    "name-de": "Malediven",
    insideEU: false,
    languages: ["dv"],
  },
  {
    code: "ML",
    code3: "MLI",
    name: "Mali",
    "name-de": "Mali",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "MT",
    code3: "MLT",
    name: "Malta",
    "name-de": "Malta",
    insideEU: true,
    languages: ["mt", "en"],
    vatPrefix: "MT",
    vatExample: "11679112",
  },
  {
    code: "MH",
    code3: "MHL",
    name: "Marshall Islands",
    "name-de": "Marshallinseln",
    insideEU: false,
    languages: ["en", "mh"],
  },
  {
    code: "MQ",
    code3: "MTQ",
    name: "Martinique",
    "name-de": "Martinique",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "MR",
    code3: "MRT",
    name: "Mauritania",
    "name-de": "Mauritanien",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "MU",
    code3: "MUS",
    name: "Mauritius",
    "name-de": "Mauritius",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "YT",
    code3: "MYT",
    name: "Mayotte",
    "name-de": "Mayotte",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "MX",
    code3: "MEX",
    name: "Mexico",
    "name-de": "Mexiko",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "FM",
    code3: "FSM",
    name: "Federated States of Micronesia",
    "name-de": "Föderierte Staaten von Mikronesien",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "MD",
    code3: "MDA",
    name: "Moldova",
    "name-de": "Republik Moldau",
    insideEU: false,
    languages: ["ro"],
  },
  {
    code: "MC",
    code3: "MCO",
    name: "Monaco",
    "name-de": "Monaco, Fürstentum Monaco",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "MN",
    code3: "MNG",
    name: "Mongolia",
    "name-de": "Mongolei",
    insideEU: false,
    languages: ["mn"],
  },
  {
    code: "ME",
    code3: "MNE",
    name: "Montenegro",
    "name-de": "Montenegro",
    insideEU: false,
    languages: ["sr", "bs", "sq", "hr"],
  },
  {
    code: "MS",
    code3: "MSR",
    name: "Montserrat",
    "name-de": "Montserrat",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "MA",
    code3: "MAR",
    name: "Morocco",
    "name-de": "Marokko",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "MZ",
    code3: "MOZ",
    name: "Mozambique",
    "name-de": "Mosambik",
    insideEU: false,
    languages: ["pt"],
  },
  {
    code: "MM",
    code3: "MMR",
    name: "Myanmar",
    "name-de": "Myanmar",
    insideEU: false,
    languages: ["my"],
  },
  {
    code: "NA",
    code3: "NAM",
    name: "Namibia",
    "name-de": "Namibia",
    insideEU: false,
    languages: ["en", "af"],
  },
  {
    code: "NR",
    code3: "NRU",
    name: "Nauru",
    "name-de": "Nauru",
    insideEU: false,
    languages: ["en", "na"],
  },
  {
    code: "NP",
    code3: "NPL",
    name: "Nepal",
    "name-de": "",
    insideEU: false,
    languages: ["ne"],
  },
  {
    code: "NL",
    code3: "NLD",
    name: "Netherlands",
    "name-de": "Niederlande, Holland",
    insideEU: true,
    languages: ["nl"],
    vatPrefix: "NL",
    vatExample: "004495445B01",
  },
  {
    code: "NC",
    code3: "NCL",
    name: "New Caledonia",
    "name-de": "Neukaledonien",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "NZ",
    code3: "NZL",
    name: "New Zealand",
    "name-de": "Neuseeland",
    insideEU: false,
    languages: ["en", "mi"],
  },
  {
    code: "NI",
    code3: "NIC",
    name: "Nicaragua",
    "name-de": "Nicaragua",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "NE",
    code3: "NER",
    name: "Niger",
    "name-de": "Niger",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "NG",
    code3: "NGA",
    name: "Nigeria",
    "name-de": "Nigeria",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "NU",
    code3: "NIU",
    name: "Niue",
    "name-de": "Niue",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "NF",
    code3: "NFK",
    name: "Norfolk Island",
    "name-de": "Norfolkinsel",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "MP",
    code3: "MNP",
    name: "Northern Mariana Islands",
    "name-de": "Nördliche Marianen",
    insideEU: false,
    languages: ["en", "ch"],
  },
  {
    code: "NO",
    code3: "NOR",
    name: "Norway",
    "name-de": "Norwegen",
    insideEU: false,
    languages: ["no", "nb", "nn"],
  },
  {
    code: "OM",
    code3: "OMN",
    name: "Oman",
    "name-de": "Oman",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "PK",
    code3: "PAK",
    name: "Pakistan",
    "name-de": "",
    insideEU: false,
    languages: ["en", "ur"],
  },
  {
    code: "PW",
    code3: "PLW",
    name: "Palau",
    "name-de": "Palau",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "PS",
    code3: "PSE",
    name: "Palestine",
    "name-de": "Palästina",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "PA",
    code3: "PAN",
    name: "Panama",
    "name-de": "Panama",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "PG",
    code3: "PNG",
    name: "Papua New Guinea",
    "name-de": "Papua-Neuguinea",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "PY",
    code3: "PRY",
    name: "Paraguay",
    "name-de": "Paraguay",
    insideEU: false,
    languages: ["es", "gn"],
  },
  {
    code: "PE",
    code3: "PER",
    name: "Peru",
    "name-de": "Peru",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "PH",
    code3: "PHL",
    name: "Philippines",
    "name-de": "Philippinen",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "PN",
    code3: "PCN",
    name: "Pitcairn",
    "name-de": "Pitcairn",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "PL",
    code3: "POL",
    name: "Poland",
    "name-de": "Polen",
    insideEU: true,
    languages: ["pl"],
    vatPrefix: "PL",
    vatExample: "8567346216",
  },
  {
    code: "PT",
    code3: "PRT",
    name: "Portugal",
    "name-de": "Portugal",
    insideEU: true,
    languages: ["pt"],
    vatPrefix: "PT",
    vatExample: "501964842",
  },
  {
    code: "PR",
    code3: "PRI",
    name: "Puerto Rico",
    "name-de": "Puerto Rico",
    insideEU: false,
    languages: ["es", "en"],
  },
  {
    code: "QA",
    code3: "QAT",
    name: "Qatar",
    "name-de": "Katar",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "MK",
    code3: "MKD",
    name: "Republic of North Macedonia",
    "name-de": "Ehemalige jugoslawische Republik Mazedonien",
    insideEU: false,
    languages: ["mk"],
  },
  {
    code: "RO",
    code3: "ROU",
    name: "Romania",
    "name-de": "Rumänien",
    insideEU: true,
    languages: ["ro"],
    vatPrefix: "RO",
    vatExample: "18547291",
  },
  {
    code: "RU",
    code3: "RUS",
    name: "Russian Federation",
    "name-de": "Russland, Russische Föderation",
    insideEU: false,
    languages: ["ru"],
  },
  {
    code: "RW",
    code3: "RWA",
    name: "Rwanda",
    "name-de": "Ruanda, Rwanda",
    insideEU: false,
    languages: ["rw", "en", "fr"],
  },
  {
    code: "RE",
    code3: "REU",
    name: "Réunion",
    "name-de": "Reunion",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "BL",
    code3: "BLM",
    name: "Saint Barthélemy",
    "name-de": "Saint Barthélemy",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "SH",
    code3: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    "name-de": "Sfânta Elena",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "KN",
    code3: "KNA",
    name: "Saint Kitts and Nevis",
    "name-de": "St. Kitts und Nevis",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "LC",
    code3: "LCA",
    name: "Saint Lucia",
    "name-de": "St. Lucia",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "MF",
    code3: "MAF",
    name: "Saint Martin (French part)",
    "name-de": "Sint Maarten",
    insideEU: false,
    languages: ["en", "fr", "nl"],
  },
  {
    code: "PM",
    code3: "SPM",
    name: "Saint Pierre and Miquelon",
    "name-de": "St. Pierre und Miquelon",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "VC",
    code3: "VCT",
    name: "Saint Vincent and the Grenadines",
    "name-de": "St. Vincent und die Grenadinen",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "WS",
    code3: "WSM",
    name: "Samoa",
    "name-de": "Samoa",
    insideEU: false,
    languages: ["sm", "en"],
  },
  {
    code: "SM",
    code3: "SMR",
    name: "San Marino",
    "name-de": "San Marino",
    insideEU: false,
    languages: ["it"],
  },
  {
    code: "ST",
    code3: "STP",
    name: "Sao Tome and Principe",
    "name-de": "São Tomé und Príncipe",
    insideEU: false,
    languages: ["pt"],
  },
  {
    code: "SA",
    code3: "SAU",
    name: "Saudi Arabia",
    "name-de": "Saudi-Arabien",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "SN",
    code3: "SEN",
    name: "Senegal",
    "name-de": "Senegal",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "RS",
    code3: "SRB",
    name: "Serbia",
    "name-de": "Serbien",
    insideEU: false,
    languages: ["sr"],
  },
  {
    code: "SC",
    code3: "SYC",
    name: "Seychelles",
    "name-de": "Seychellen",
    insideEU: false,
    languages: ["fr", "en"],
  },
  {
    code: "SL",
    code3: "SLE",
    name: "Sierra Leone",
    "name-de": "Sierra Leone",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "SG",
    code3: "SGP",
    name: "Singapore",
    "name-de": "Singapur",
    insideEU: false,
    languages: ["en", "ms", "ta", "zh"],
  },
  {
    code: "SX",
    code3: "SXM",
    name: "Sint Maarten (Dutch part)",
    "name-de": "Sint Maarten (Niederländischer Teil)",
    insideEU: false,
    languages: ["nl", "en"],
  },
  {
    code: "SK",
    code3: "SVK",
    name: "Slovakia",
    "name-de": "Slowakei",
    insideEU: true,
    languages: ["sk"],
    vatPrefix: "SK",
    vatExample: "2022749619",
  },
  {
    code: "SI",
    code3: "SVN",
    name: "Slovenia",
    "name-de": "Slovenien",
    insideEU: true,
    languages: ["sl"],
    vatPrefix: "SI",
    vatExample: "50223054",
  },
  {
    code: "SB",
    code3: "SLB",
    name: "Solomon Islands",
    "name-de": "Salomonen",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "SO",
    code3: "SOM",
    name: "Somalia",
    "name-de": "Somalia",
    insideEU: false,
    languages: ["so", "ar"],
  },
  {
    code: "ZA",
    code3: "ZAF",
    name: "South Africa",
    "name-de": "Südafrika",
    insideEU: false,
    languages: ["af", "en", "nr", "st", "ss", "tn", "ts", "ve", "xh", "zu"],
  },
  {
    code: "GS",
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    "name-de": "Südgeorgien und die Südlichen Sandwichinseln	",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "SS",
    code3: "SSD",
    name: "South Sudan",
    "name-de": "Südsudan",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "ES",
    code3: "ESP",
    name: "Spain",
    "name-de": "Spanien",
    insideEU: true,
    languages: ["es", "eu", "ca", "gl", "oc"],
    vatPrefix: "ES",
    vatExample: "B78640570",
  },
  {
    code: "LK",
    code3: "LKA",
    name: "Sri Lanka",
    "name-de": "Sri Lanka",
    insideEU: false,
    languages: ["si", "ta"],
  },
  {
    code: "SD",
    code3: "SDN",
    name: "Sudan",
    "name-de": "Sudan",
    insideEU: false,
    languages: ["ar", "en"],
  },
  {
    code: "SR",
    code3: "SUR",
    name: "Suriname",
    "name-de": "Suriname",
    insideEU: false,
    languages: ["nl"],
  },
  {
    code: "SJ",
    code3: "SJM",
    name: "Svalbard and Jan Mayen",
    "name-de": "Svalbard und Jan Mayen",
    insideEU: false,
    languages: ["no"],
  },
  {
    code: "SE",
    code3: "SWE",
    name: "Sweden",
    "name-de": "Schweden",
    insideEU: true,
    languages: ["sv"],
    vatPrefix: "SE",
    vatExample: "123456789701",
  },
  {
    code: "CH",
    code3: "CHE",
    name: "Switzerland",
    "name-de": "Schweiz",
    insideEU: false,
    languages: ["de", "fr", "it"],
  },
  {
    code: "SY",
    code3: "SYR",
    name: "Syrian Arab Republic",
    "name-de": "Syrien",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "TW",
    code3: "TWN",
    name: "Taiwan",
    "name-de": "Taiwan",
    insideEU: false,
    languages: ["zh"],
  },
  {
    code: "TJ",
    code3: "TJK",
    name: "Tajikistan",
    "name-de": "Tadschikistan",
    insideEU: false,
    languages: ["tg", "ru"],
  },
  {
    code: "TZ",
    code3: "TZA",
    name: "Tanzania",
    "name-de": "Tansania",
    insideEU: false,
    languages: ["sw", "en"],
  },
  {
    code: "TH",
    code3: "THA",
    name: "Thailand",
    "name-de": "Thailand",
    insideEU: false,
    languages: ["th"],
  },
  {
    code: "TL",
    code3: "TLS",
    name: "Timor-Leste",
    "name-de": "Osttimor",
    insideEU: false,
    languages: ["pt"],
  },
  {
    code: "TG",
    code3: "TGO",
    name: "Togo",
    "name-de": "Togo",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "TK",
    code3: "TKL",
    name: "Tokelau",
    "name-de": "Tokelau",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "TO",
    code3: "TON",
    name: "Tonga",
    "name-de": "Tonga",
    insideEU: false,
    languages: ["en", "to"],
  },
  {
    code: "TT",
    code3: "TTO",
    name: "Trinidad and Tobago",
    "name-de": "Trinidad und Tobago",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "TN",
    code3: "TUN",
    name: "Tunisia",
    "name-de": "Tunesien",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "TR",
    code3: "TUR",
    name: "Turkey",
    "name-de": "Türkei",
    insideEU: false,
    languages: ["tr"],
  },
  {
    code: "TM",
    code3: "TKM",
    name: "Turkmenistan",
    "name-de": "Turkmenistan",
    insideEU: false,
    languages: ["tk", "ru"],
  },
  {
    code: "TC",
    code3: "TCA",
    name: "Turks and Caicos Islands",
    "name-de": "Turks- und Caicosinseln",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "TV",
    code3: "TUV",
    name: "Tuvalu",
    "name-de": "Tuvalu",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "UG",
    code3: "UGA",
    name: "Uganda",
    "name-de": "Uganda",
    insideEU: false,
    languages: ["en", "sw"],
  },
  {
    code: "UA",
    code3: "UKR",
    name: "Ukraine",
    "name-de": "Ukraine",
    insideEU: false,
    languages: ["uk"],
  },
  {
    code: "AE",
    code3: "ARE",
    name: "United Arab Emirates",
    "name-de": "Vereinigte Arabische Emirate",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "GB",
    code3: "GBR",
    name: "United Kingdom of Great Britain and Northern Ireland",
    "name-de": "Vereinigtes Königreich (Großbritannien, England)",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "UM",
    code3: "UMI",
    name: "United States Minor Outlying Islands",
    "name-de": "Amerikanisch-Ozeanien",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "US",
    code3: "USA",
    name: "United States of America",
    "name-de": "Vereinigte Staaten (Amerika, USA)",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "UY",
    code3: "URY",
    name: "Uruguay",
    "name-de": "Uruguay",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "UZ",
    code3: "UZB",
    name: "Uzbekistan",
    "name-de": "Usbekistan",
    insideEU: false,
    languages: ["uz", "ru"],
  },
  {
    code: "VU",
    code3: "VUT",
    name: "Vanuatu",
    "name-de": "Vanuatu",
    insideEU: false,
    languages: ["bi", "en", "fr"],
  },
  {
    code: "VE",
    code3: "VEN",
    name: "Venezuela",
    "name-de": "Venezuela",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "VN",
    code3: "VNM",
    name: "Vietnam",
    "name-de": "Vietnam",
    insideEU: false,
    languages: ["vi"],
  },
  {
    code: "VG",
    code3: "VGB",
    name: "Virgin Islands (British)",
    "name-de": "Britische Jungferninseln",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "VI",
    code3: "VIR",
    name: "Virgin Islands (U.S.)",
    "name-de": "Amerikanische Jungferninseln",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "WF",
    code3: "WLF",
    name: "Wallis and Futuna",
    "name-de": "Wallis und Futuna",
    insideEU: false,
    languages: ["fr"],
  },
  {
    code: "EH",
    code3: "ESH",
    name: "Western Sahara",
    "name-de": "Westsahara",
    insideEU: false,
    languages: ["es"],
  },
  {
    code: "YE",
    code3: "YEM",
    name: "Yemen",
    "name-de": "Jemen",
    insideEU: false,
    languages: ["ar"],
  },
  {
    code: "ZM",
    code3: "ZMB",
    name: "Zambia",
    "name-de": "Sambia",
    insideEU: false,
    languages: ["en"],
  },
  {
    code: "ZW",
    code3: "ZWE",
    name: "Zimbabwe",
    "name-de": "Simbabwe",
    insideEU: false,
    languages: ["en", "sn", "nd"],
  },
  {
    code: "AX",
    code3: "ALA",
    name: "Åland Islands",
    "name-de": "Alandinseln",
    insideEU: false,
    languages: ["sv"],
  },
];

export default countryList;

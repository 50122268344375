export const FORM_STATUS = {
  LOADING: "pending",
  ERROR: "error",
  SUCCESS: "success",
};

export const ROLES = {
  USER: "user",
  ADMIN: "admin",
};

export const DEFAULT_ITEMS_PER_PAGE = 10;
export const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const PLATFORM_TYPES = {
  GOOGLE: "google",
  AMAZON: "amazon",
  APPLE_PODCASTS: "applePodcasts",
  FACEBOOK: "facebook",
  TRUSTPILOT: "trustpilot",
  KUNUNU: "kununu",
  GLASSDOOR: "glassdoor",
};

export const PLANTING_PROJECTS = {
  1: "Plant-for-the-Planet",
  2: "Eden Reforestation Projects",
  3: "ReForest Now",
};

export const PLATFORM_COLORS = {
  applePodcasts: "#FF3B30",
  google: "#4285F4",
  kununu: "#FF6600",
  trustpilot: "#00B67A",
  amazon: "#FF9900",
  facebook: "#1877F2",
  glassdoor: "#0CAA41",
};

export const PROJECT_COLORS = {
  1: "#4285F4",
  2: "#FF9900",
  3: "#00A651",
};

export const FOREST_TYPES = {
  BRAND: "brand",
  SINGLE: "single",
};

export const TREE_PLANTING_OPTION = {
  ONLY_NEW_REVIEWS: 1,
  ALL_REVIEWS: 0,
};

export const INVOICE_STATUS = {
  DRAFT: "draft",
  PARTIAL_REFUND: "pratial_refund",
  FULL_REFUND: "full_refund",
  PAID: "paid",
};

export const INVOICE_TYPES = {
  MESSAGE: "message",
  PLAN: "plan",
  PRINT: "print",
  TREES: "planting-project",
};

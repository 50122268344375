<template>
  <v-list bg-color="transparent">
    <v-img
      max-width="80"
      class="mx-auto mt-5 mb-10"
      src="../assets/images/logo.svg"
    ></v-img>

    <v-list-item
      v-for="item in list"
      :key="item.text"
      :to="item.link"
      variant="contained"
    >
      <v-list-item-avatar start>
        <v-icon :icon="item.icon"></v-icon>
      </v-list-item-avatar>
      <v-list-item-title>{{ item.text }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "TheSidebar",
  data() {
    return {
      list: [
        {
          text: "Users",
          link: { name: "Users" },
          icon: "mdi-account-group",
        },
        {
          text: "Forests",
          link: { name: "Forests" },
          icon: "mdi-account-group",
        },
        {
          text: "Companies",
          link: { name: "Companies" },
          icon: "mdi-domain",
        },
        {
          text: "Visitors",
          link: { name: "Visitors" },
          icon: "mdi-domain",
        },
        {
          text: "Emails",
          link: { name: "Emails" },
          icon: "mdi-domain",
        },
        {
          text: "Trees",
          link: { name: "TreePlanting" },
          icon: "mdi-domain",
        },
      ],
    };
  },
};
</script>

<template>
  <v-switch
    :loading="loading"
    :input-value="user.emailVerified"
    @click="updateEmailVerification"
    readonly
  ></v-switch>
</template>
<script>
import { API } from "../../api";

export default {
  name: "UserVerifyEmail",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async updateEmailVerification() {
      this.loading = true;
      try {
        await API.updateUserEmailVerify({
          id: this.user.id,
          emailVerified: !this.user.emailVerified,
        });
      } catch (error) {
        this.loading = false;
        return;
      }
      this.loading = false;
      this.$emit("updateEmailVerification", !this.user.emailVerified);
    },
  },
};
</script>

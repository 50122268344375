<template>
  <v-card>
    <v-card-title>List of Trees by Forest Platform</v-card-title>
    <v-data-table
      :headers="headers"
      :items="treeReports"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalAmount"
      :footer-props="{
        'items-per-page-options': $options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      }"
    >
      <template v-slot:[`item.forest`]="{ item }">
        <router-link :to="{ name: 'Forest', params: { id: item.forest.id } }">
          {{ item.forest.name }}
        </router-link>
      </template>
      <template v-slot:[`item.ownedBy`]="{ item }">
        <router-link :to="{ name: 'Company', params: { id: item.ownedBy.id } }">
          {{ item.ownedBy.name }}
        </router-link>
      </template>
      <template v-slot:[`item.platformType`]="{ item }">
        <platforms-logo :platforms="[item.platformType]"></platforms-logo>
      </template>
      <template v-slot:[`item.projectId`]="{ item }">
        {{ PLANTING_PROJECTS[item.projectId] }}
      </template>
      <template v-slot:[`item.countingTimestamp`]="{ item }">
        {{ formatDate(item.countingTimestamp * 1000) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { API } from "../../api";
import { formatDate } from "../../helpers";
import { PLANTING_PROJECTS } from "../../constants";

import PlatformsLogo from "../Base/PlatformsLogo.vue";

export default {
  name: "TreeReportsTable",
  components: {
    PlatformsLogo,
  },
  data() {
    return {
      formatDate,
      PLANTING_PROJECTS,
      treeReports: [],
      totalAmount: 0,
      loading: false,
      headers: [
        {
          text: "Company",
          value: "ownedBy",
          width: "300",
          sortable: false,
        },
        {
          text: "Forest",
          value: "forest",
          width: "300",
          sortable: false,
        },
        {
          text: "Platform",
          value: "platformType",
          width: "200",
          sortable: false,
        },
        {
          text: "Project",
          value: "projectId",
          width: "250",
          sortable: false,
        },
        {
          text: "Planting Date",
          value: "countingTimestamp",
          width: "250",
        },
        {
          text: "Tree Amount",
          value: "reviewAmount",
          align: "center",
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        previousPage: 0,
      },
    };
  },
  created() {
    this.getTreeReports();
  },
  watch: {
    options: {
      handler: "debounceFetching",
      deep: true,
    },
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.getTreeReports();
      }, 500);
    },
    async getTreeReports() {
      this.loading = true;
      try {
        const getPlantingReportForTable = await API.getPlantingReports({
          sortBy: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? "desc" : "asc",
          page: this.options.page,
          pageSize:
            this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage,
        });

        this.treeReports = getPlantingReportForTable.data;
        this.totalAmount = getPlantingReportForTable.count;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[(_vm.loading)?_c(VSkeletonLoader,{attrs:{"type":"text"}}):_vm._e(),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"3"}},[_c(VCardTitle,[_vm._v("Crawl Requests")]),_c(VCardSubtitle,[_c('DateRangePicker',{attrs:{"ranges":_vm.ranges,"opens":"right","singleDatePicker":false,"maxDate":new Date()},on:{"update":_vm.getChartData},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(_vm._f("date")(picker.startDate))+" - "+_vm._s(_vm._f("date")(picker.endDate))+" ")]}}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c(VSelect,{attrs:{"items":_vm.forests,"item-value":"id","item-text":"name","label":"Select Forests","multiple":""},on:{"change":_vm.getChartData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c(VChip,[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"text-grey text-caption align-self-center"},[_vm._v(" (+"+_vm._s(_vm.selectedForests.length - 2)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedForests),callback:function ($$v) {_vm.selectedForests=$$v},expression:"selectedForests"}})],1)],1),_c(VCol,{attrs:{"cols":"9"}},[[_c('bar-chart',{attrs:{"chartData":_vm.formattedChartData}})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="!loading">
    <div class="submenu mb-4">
      <button
        v-for="(item, index) in menuItems"
        :key="index"
        @click="activeView = item.value"
        :class="['menu-button', { active: activeView === item.value }]"
        :title="item.tooltip ? item.tooltip() : ''"
      >
        {{ typeof item.label === "function" ? item.label() : item.label }}
      </button>
    </div>

    <user-profile
      v-if="activeView === 'profile'"
      :user="user"
      :workspaces="workspaces"
      :selectedWorkspace="selectedWorkspace"
      v-model="selectedWorkspaceId"
      @updateEmail="updateEmail"
      @updateEmailVerification="updateEmailVerification"
      class="mb-10"
    />
    <!-- <user-forest v-if="activeView === 'forest'" :user="user" class="mb-10" /> -->
    <company-forests
      v-if="activeView === 'forests'"
      :companyId="selectedCompanyId"
      class="mb-10"
    />
    <!-- <user-billing v-if="activeView === 'billing'" :user="user" class="mb-10" /> -->
    <company-billing-info
      v-if="activeView === 'billing'"
      :id="selectedCompanyId"
      class="mb-10"
    />
    <user-emails v-if="activeView === 'emails'" :id="user.id" class="mb-10" />
    <company-invoices
      v-if="activeView === 'invoices'"
      :id="selectedCompanyId"
      class="mb-10"
    />
  </div>
</template>
<script>
import UserProfile from "../components/User/UserProfile.vue";
import UserEmails from "../components/User/UserEmails.vue";
import CompanyForests from "../components/Company/CompanyForests.vue";
import CompanyBillingInfo from "../components/Company/CompanyBillingInfo.vue";
import CompanyInvoices from "../components/Company/CompanyInvoices.vue";
import { API } from "../api";

export default {
  name: "UserViews",
  components: {
    UserProfile,
    CompanyForests,
    CompanyBillingInfo,
    CompanyInvoices,
    UserEmails,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      selectedWorkspaceId: null,
      workspaces: [],
      user: {},
      activeView: "profile",
      menuItems: [
        {
          label: () => {
            const maxLength = 10;
            const email = this.user?.email || "User Profile";
            return email.length > maxLength
              ? email.substring(0, maxLength) + "..."
              : email;
          },
          value: "profile",
          tooltip: () => this.user?.email,
        },
        { label: "Forests", value: "forests" },
        { label: "Billing", value: "billing" },
        { label: "Emails", value: "emails" },
        { label: "Invoices", value: "invoices" },
      ],
    };
  },
  async created() {
    this.loading = true;
    try {
      const [user, workspaces] = await Promise.all([
        API.getUser({ userId: this.id }),
        API.getUserWorkspace({ userId: this.id }),
      ]);
      this.user = user;
      this.workspaces = workspaces;
      if (this.workspaces.length > 0) {
        this.selectedWorkspaceId = this.workspaces[0].id;
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      return;
    }
  },
  computed: {
    selectedCompanyId() {
      return this.selectedWorkspace?.companyId.id;
    },
    selectedWorkspace() {
      return this.workspaces.find(
        (workspace) => this.selectedWorkspaceId === workspace.id
      );
    },

    breadcrumbs() {
      return [
        {
          text: "Users",
          to: { name: "Users" },
          exact: true,
        },
        {
          text: this.user?.email,
        },
      ];
    },
  },
  methods: {
    updateEmail(email) {
      this.user.email = email;
    },
    updateEmailVerification(value) {
      this.user.emailVerified = value;
    },
  },
};
</script>

<style scoped>
.submenu {
  display: flex;
  gap: 1rem;
}

.menu-button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  width: 150px;
}

.menu-button.active {
  background: rgb(226, 225, 225);
  color: black;
  border-color: rgb(226, 225, 225);
}
</style>

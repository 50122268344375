<template>
  <img
    v-if="platform === $options.PLATFORM_TYPES.AMAZON"
    src="../../assets/images/platforms/amazon.svg"
    alt=""
    :key="platform"
  />
  <img
    v-else-if="platform === $options.PLATFORM_TYPES.GOOGLE"
    src="../../assets/images/platforms/google.svg"
    alt=""
    :key="platform"
  />
  <img
    v-else-if="platform === $options.PLATFORM_TYPES.FACEBOOK"
    src="../../assets/images/platforms/facebook.svg"
    alt=""
    :key="platform"
  />
  <img
    v-else-if="platform === $options.PLATFORM_TYPES.TRUSTPILOT"
    src="../../assets/images/platforms/trustpilot.svg"
    alt=""
    :key="platform"
  />
  <img
    v-else-if="platform === $options.PLATFORM_TYPES.KUNUNU"
    src="../../assets/images/platforms/kununu.svg"
    alt=""
    :key="platform"
  />
  <img
    v-else-if="platform === $options.PLATFORM_TYPES.GLASSDOOR"
    src="../../assets/images/platforms/glassdoor.svg"
    alt=""
    :key="platform"
  />
</template>
<script>
import { PLATFORM_TYPES } from "../../constants";

export default {
  name: "PlatformLogo",
  PLATFORM_TYPES,
  props: {
    platform: {
      type: String,
      required: true,
      validator: (platform) => Object.values(PLATFORM_TYPES).includes(platform),
    },
  },
};
</script>

<template>
  <span>
    <v-dialog v-model="dialogRemoveUser" max-width="600px">
      <v-card>
        <v-card-title>Remove User</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="submitForm">
            <v-row>
              <v-col cols="4">
                Type
                <v-select
                  :items="removeUserTypes"
                  density="compact"
                  value="delete"
                  v-model="removeUserType"
                  item-text="name"
                  item-value="value"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="8">
                Reason
                <v-select
                  :items="feedbackTypes"
                  density="compact"
                  value="feedback"
                  v-model="feedbackType"
                  item-text="name"
                  item-value="value"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
            </v-row>
            <v-text-field
              v-model="comment"
              label="Comment"
              type="text"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" @click="submitForm"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmation" max-width="600px">
      <v-card>
        <v-card-title> Warning! <br /> </v-card-title>
        <v-card-text>
          Verify user identity before removing the account!
          <br />
          <br />
          You are about to remove the user
          <b>{{ userEmail }}</b
          >.
          <v-text-field
            v-model="removeConfirmTextModel"
            type="text"
            :label="removeConfirmLabel"
            :rules="[rules.required, rules.confirmType]"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-alert v-if="error" density="compact" type="error" title="Error">
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogConfirmation = false">Cancel</v-btn>
          <v-btn
            :disabled="
              !removeConfirmTextModel ||
              removeConfirmTextModel !== removeConfirmText
            "
            color="primary"
            @click="removeUser"
          >
            <v-progress-circular
              v-if="loading"
              indeterminate
              size="20"
              width="2"
              color="white"
            ></v-progress-circular>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import { API } from "../../api";

export default {
  name: "UserUpdateEmail",
  props: {
    id: {
      type: String,
    },
    email: {
      type: String,
    },
  },
  data() {
    return {
      comment: "",
      error: null,
      feedback: "",
      feedbackType: "",
      feedbackTypes: [
        {
          name: "Customer service was less than expected",
          value: "customer_service",
        },
        {
          name: "Quality was less than expected",
          value: "low_quality",
        },
        {
          name: "Some features are missing",
          value: "missing_features",
        },
        {
          name: "I’m switching to a different service",
          value: "switched_service",
        },
        {
          name: "Ease of use was less than expected",
          value: "too_complex",
        },
        {
          name: "It’s too expensive",
          value: "too_expensive",
        },
        {
          name: "I don’t use the service enough",
          value: "unused",
        },
        {
          name: "Other",
          value: "other",
        },
      ],
      removeConfirmLabel: "",
      removeConfirmText: "",
      removeConfirmTextModel: "",
      removeUserType: "",
      removeUserTypes: [
        {
          name: "Block",
          value: "blocked",
        },
        {
          name: "Delete",
          value: "deleted",
        },
      ],
      rules: {
        required: (v) => !!v || "Required.",
        confirmType: (v) =>
          v === this.removeConfirmText || "Text must be valid",
      },
      userEmail: "",
      dialogRemoveUser: false,
      dialogConfirmation: false,
      loading: false,
    };
  },
  watch: {
    dialogRemoveUser: {
      immediate: true,
      handler(open) {
        if (open) {
          this.userEmail = this.email;
        }
        this.removeConfirmTextModel = "";
      },
    },
    removeUserType: {
      deep: true,
      async handler() {
        const type = this.removeUserTypes.find(
          (t) => t.value === this.removeUserType
        ).name;
        this.removeConfirmText = type.toUpperCase();
        this.removeConfirmLabel = `Please type '${this.removeConfirmText}'`;
        this.removeConfirmTextModel = "";
      },
    },
  },
  methods: {
    closeDialog() {
      this.dialogRemoveUser = false;
      this.dialogConfirmation = false;
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.dialogConfirmation = true;
      }
    },
    async removeUser() {
      this.loading = true;
      this.error = null;
      try {
        await API.removeUser({
          id: this.id,
          type: this.removeUserType,
          comment: this.comment,
          feedback: this.feedbackType,
        });
        this.loading = false;
        this.closeDialog();
        this.$router.push({ name: "Users" });
      } catch (error) {
        this.error = error?.response?.data?.message ?? "Unknown Error";
        this.loading = false;
        return;
      }
    },
  },
};
</script>

<template>
  <v-container fluid class="fill-height">
    <v-row justify="center" align="center" class="fill-height">
      <v-col>
        <v-card
          title="SignIn"
          class="pa-5 mx-auto"
          max-width="480"
          width="100%"
        >
          <v-alert
            v-if="status === $options.FORM_STATUS.ERROR"
            density="compact"
            type="error"
            title="Error"
            >{{ error }}</v-alert
          >

          <v-btn block @click="signInUser">Sign in with Google</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { GoogleAuthProvider } from "firebase/auth";
import { FORM_STATUS } from "../constants";

export default {
  name: "SignInView",
  FORM_STATUS,
  data() {
    return {
      error: null,
      status: null,
    };
  },
  created() {
    if (this.$route.query.message === "forbidden") {
      this.error = "Access Forbidden";
      this.status = FORM_STATUS.ERROR;
    }
  },
  methods: {
    ...mapActions(["signIn"]),
    async signInUser() {
      this.status = FORM_STATUS.LOADING;
      const userData = {
        lang: "en",
        provider: new GoogleAuthProvider(),
      };
      userData.provider.setCustomParameters({
        prompt: "select_account",
      });

      try {
        await this.signIn(userData);
        this.status = FORM_STATUS.SUCCESS;
        this.$router.push(
          this.$router.history.current.query.redirect || { name: "Users" }
        );
      } catch (error) {
        if (error.response?.data?.message) {
          this.error = error.response.data.message;
        } else if (error.code) {
          const errorsToSkip = [
            "auth/popup-closed-by-user",
            "auth/cancelled-popup-request",
          ];
          if (errorsToSkip.includes(error.code)) {
            this.loading = false;
            return;
          }
          this.error = error.code;
        } else {
          this.error = "Unknown Error";
        }
        this.status = FORM_STATUS.ERROR;
      }
    },
  },
};
</script>

<template>
  <div>
    <platform-logo
      v-for="platform in platforms"
      :key="platform"
      :platform="platform"
    ></platform-logo>
  </div>
</template>
<script>
import PlatformLogo from "./PlatformLogo.vue";

export default {
  name: "PlatformsLogo",
  components: {
    PlatformLogo,
  },
  props: {
    platforms: {
      type: Array,
      required: true,
    },
  },
};
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v("Forest")]),_c(VSimpleTable,[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"300px"}},[_vm._v("ID")]),_c('td',[_c('span',{ref:"id"},[_vm._v(_vm._s(_vm.forest.id))]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.forest.id)?_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.copyLink.apply(null, arguments)}}},on),[_c('img',{attrs:{"svg-inline":"","src":require("../../assets/images/ico-copy.svg"),"alt":""}})]):_vm._e()]}}])},[_c('span',[_vm._v("Copy")])])],1)]),_c('tr',[_c('td',[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(_vm.forest.name))])]),_c('tr',[_c('td',[_vm._v("URL")]),_c('td',[_c('a',{attrs:{"href":((_vm.$options.FOREST_DOMAIN) + "/" + (_vm.forest.slug)),"target":"_blank"}},[_vm._v(_vm._s(((_vm.$options.FOREST_DOMAIN) + "/" + (_vm.forest.slug))))])])]),_c('tr',[_c('td',[_vm._v("Company")]),_c('td',[_c('router-link',{attrs:{"to":{ name: 'Company', params: { id: _vm.forest.ownedBy } }}},[_c('span',{ref:"companyId"},[_vm._v(_vm._s(_vm.forest.ownedBy))])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.forest.ownedBy)?_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.copyCompanyId.apply(null, arguments)}}},on),[_c('img',{attrs:{"svg-inline":"","src":require("../../assets/images/ico-copy.svg"),"alt":""}})]):_vm._e()]}}])},[_c('span',[_vm._v("Copy")])])],1)]),_c('tr',[_c('td',[_vm._v("Type")]),_c('td',[_vm._v(_vm._s(_vm.forest.type))])]),_c('tr',[_c('td',[_vm._v("Status")]),_c('td',[_vm._v(_vm._s(_vm.forest.active ? "Active" : "Deactivated"))])]),_c('tr',[_c('td',[_vm._v("Created")]),_c('td',[_vm._v(_vm._s(_vm.forest.createdAt))])]),(_vm.forest.type === _vm.$options.FOREST_TYPES.SINGLE)?[_c('tr',[_c('td',[_vm._v("Platforms order")]),_c('td',[_c('platforms-logo',{attrs:{"platforms":_vm.forest.platformsOrder}})],1)]),_c('tr',[_c('td',[_vm._v("Pinned platform")]),_c('td',[(_vm.forest.pinnedPlatform)?_c('platform-logo',{attrs:{"platform":_vm.forest.pinnedPlatform}}):[_vm._v("-")]],2)]),_c('tr',[_c('td',[_vm._v("Tree limit")]),_c('td',[_vm._v(" "+_vm._s(_vm.forest.plantingReviewLimit === -1 ? "Unlimited" : _vm.forest.plantingReviewLimit)+" ")])]),_c('tr',[_c('td',[_vm._v("Planting Project")]),_c('td',[_vm._v(_vm._s(_vm.$options.PLANTING_PROJECTS[_vm.forest.projectId]))])])]:_vm._e(),_c('tr',[_c('td',[_vm._v("Review amount")]),_c('td',[_vm._v(_vm._s(_vm.forest.reviewAmount))])]),_c('tr',[_c('td',[_vm._v("Planted trees")]),_c('td',[_c(VSimpleTable,[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"150px"}},[_vm._v("For reviews")]),_c('td',[_vm._v(_vm._s(_vm.forest.reviewTreeAmount))])]),_c('tr',[_c('td',[_vm._v("Additional trees")]),_c('td',[_vm._v(_vm._s(_vm.forest.additionalTreeAmount))])]),_c('tr',[_c('td',[_vm._v("Total")]),_c('td',[_vm._v(_vm._s(_vm.forest.totalTreeAmount))])])])])],1)]),_c('tr',[_c('td',[_vm._v("Rating")]),_c('td',[_vm._v(_vm._s(_vm.forest.score))])]),_c('tr',[_c('td',[_vm._v("Crawl requests")]),_c('td',[_c('router-link',{attrs:{"to":{
              name: 'ForestCrawlRequests',
              params: { id: _vm.forest.id, name: _vm.forest.name },
            }}},[_vm._v(_vm._s(_vm.forestCrawlRequests))])],1)])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
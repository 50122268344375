import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[[_c(VCardTitle,[_vm._v(" Forest Trees "+_vm._s(_vm.$options.PLANTING_PROJECTS[_vm.projectId])+" "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listOfTrees,"server-items-length":_vm.totalAmount,"item-key":"id","show-select":"","loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.projectId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$options.PLANTING_PROJECTS[item.projectId])+" ")]}},{key:"item.stripeInvoiceNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'InvoiceView',
            params: { id: item.stripeInvoiceId },
          }}},[_vm._v(" "+_vm._s(item.stripeInvoiceNumber)+" ")])]}},{key:"top",fn:function(){return [_c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete this tree? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue-darken-1","variant":"text"},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"blue-darken-1","variant":"text"},on:{"click":_vm.deleteTreeConfirm}},[_vm._v(" OK ")]),_c(VSpacer)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","disabled":!!item.stripeInvoiceId},on:{"click":function($event){return _vm.deleteTree(item)}}},on),[_c(VIcon,[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove tree")])])]}},{key:"item.text",fn:function(ref){
          var item = ref.item;
return [_c(VTooltip,{attrs:{"top":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(item.text)?_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.text.substring(0, 100) + "...")+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])]}}],null,true),model:{value:(_vm.refundTrees),callback:function ($$v) {_vm.refundTrees=$$v},expression:"refundTrees"}})],[[_c(VProgressLinear,{attrs:{"active":_vm.actionsLoading,"indeterminate":_vm.actionsLoading,"absolute":"","bottom":"","color":"indigo darken-2"}})],(_vm.refundTrees.length > 0)?_c(VCardText,[_vm._v(" "+_vm._s(_vm.refundTrees.length)+" selected tree(s): "+_vm._s(_vm.refundTrees.map(function (tree) { return tree.name; }).join(", "))+" ")]):_c(VCardText,[_vm._v("All trees")]),(_vm.creditNoteError)?_c(VAlert,{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.creditNoteError)+" ")]):_vm._e()],[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.selectedStripeInvoiceIds.length !== 1 && !_vm.invoice},on:{"click":function($event){return _vm.previewCreditNote()}}},[_vm._v(" Preview Credit Note ")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],(_vm.previewedCreditNote)?[_c(VCardText,[_vm._v(" Total credit note: "+_vm._s(_vm.previewedCreditNote.total / 100)+" "+_vm._s(_vm.previewedCreditNote.currency)+" ")]),(_vm.isIssueCreditNoteAllowed)?_c(VCardText,[(_vm.creditNoteReasons)?[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"items":_vm.creditNoteReasons,"density":"compact","value":"other","item-text":"name","item-value":"value"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1)],1)]:_vm._e(),(_vm.creditNoteMemosDE && _vm.creditNoteMemosEN)?[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"label":"Memo templates","items":_vm.invoice.language === 'de'
                  ? _vm.creditNoteMemosDE
                  : _vm.creditNoteMemosEN,"density":"compact","value":"other","item-text":"item","item-value":"item"},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})],1)],1)]:_vm._e(),_c(VResponsive,[_c(VTextField,{attrs:{"label":"What's the reason for the removal"},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})],1)],2):_vm._e(),_c(VDialog,{attrs:{"persistent":"","max-width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":!_vm.isIssueCreditNoteAllowed,"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Issue Credit Note ")])]}}],null,false,1674623911),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Issue Credit Note")]),_c(VCardText,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"50px"}},[_vm._v("Number")]),_c('td',[_vm._v(_vm._s(_vm.previewedCreditNote.number))])]),_c('tr',[_c('td',[_vm._v("Memo")]),_c('td',[_vm._v(_vm._s(_vm.memo))])]),_c('tr',[_c('td',[_vm._v("Reason")]),_c('td',[_vm._v(_vm._s(_vm.reason))])]),_c('tr',[_c('td',[_vm._v("How to credit")]),_c('td',[_vm._v(_vm._s(_vm.credit))])]),_c('tr',[_c('td',[_vm._v("Total")]),_c('td',[_vm._v(" "+_vm._s(_vm.previewedCreditNote.total / 100)+" "+_vm._s(_vm.previewedCreditNote.currency)+" ")])])])]},proxy:true}],null,false,1919328690)})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false;
              _vm.issueCreditNote();}}},[_vm._v(" Confirm ")])],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
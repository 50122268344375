<template>
  <span>
    <v-dialog v-model="dialogForestPlatformEdit" max-width="600px">
      <v-card>
        <v-card-title>Update Google Place CID</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="submitForm">
            <v-text-field
              v-model="newCid"
              label="cid"
              type="text"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" @click="submitForm"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmation" max-width="600px">
      <v-card>
        <v-card-title> Warning! <br /> </v-card-title>
        <v-card-text>
          Verify Google Map CID before updating!
          <br />
          <br />
          <b>
            <a
              target="_blank"
              :href="'https://maps.google.com/maps?cid=' + newCid"
            >
              https://maps.google.com/maps?cid={{ newCid }}
            </a>
          </b>
        </v-card-text>
        <v-card-text>
          <v-alert v-if="error" density="compact" type="error" title="Error">
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogConfirmation = false">Cancel</v-btn>
          <v-btn color="primary" @click="updateForestPlatform">
            <v-progress-circular
              v-if="loading"
              indeterminate
              size="20"
              width="2"
              color="white"
            ></v-progress-circular>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import { API } from "../../api";

export default {
  name: "ForestPlatformEdit",
  props: {
    platform: {
      type: Object,
    },
  },
  data() {
    return {
      requiredRule: (v) => !!v || "This field is required",
      comment: "",
      error: null,
      newCid: this.platform.keys.dataCid,
      dialogForestPlatformEdit: false,
      dialogConfirmation: false,
      loading: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialogForestPlatformEdit = false;
      this.dialogConfirmation = false;
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.dialogConfirmation = true;
      }
    },
    async updateForestPlatform() {
      this.loading = true;
      this.error = null;
      try {
        await API.updateForestPlatform({
          forestId: this.platform.forest,
          platformType: this.platform.type,
          forestPlatformData: { newCid: this.newCid },
        });

        this.$router.push({
          name: "Company",
          params: { id: this.platform.ownedBy },
        });

        this.loading = false;
        this.closeDialog();
      } catch (error) {
        this.error = error?.response?.data?.message ?? "Unknown Error";
        this.loading = false;
        return;
      }
    },
  },
};
</script>

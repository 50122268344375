<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title class="d-flex align-center">
          Select Date Range:
          <DateRangePicker
            :ranges="ranges"
            v-model="dateRange"
            opens="right"
            :singleDatePicker="false"
            :maxDate="new Date()"
            @update="debounceFetching"
          >
            <template v-slot:input="picker">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
          </DateRangePicker>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-list class="ml-5">
                <v-list-item>
                  <v-chip color="gray" text-color="black">
                    <v-skeleton-loader v-if="loading" type="text" width="150px">
                    </v-skeleton-loader>
                    <template v-else>
                      Total:
                      {{ calculatedTotalPlantingReportsByPlatform }}
                    </template>
                  </v-chip>
                </v-list-item>
                <v-list-item
                  v-for="(amount, platform) in platformTotals"
                  :key="platform"
                >
                  <v-chip :color="PLATFORM_COLORS[platform]" text-color="white">
                    <v-skeleton-loader v-if="loading" type="text" width="150px">
                    </v-skeleton-loader>
                    <template v-else>
                      {{
                        platform.charAt(0).toUpperCase() + platform.slice(1)
                      }}: {{ amount }} ({{
                        (
                          (amount / calculatedTotalPlantingReportsByPlatform) *
                          100
                        ).toFixed(2)
                      }}%)
                    </template>
                  </v-chip>
                </v-list-item>
              </v-list>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <PieChartPlatform :chartData="plantingReportByPlatformsData" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card>
        <v-row>
          <v-col cols="12" md="6">
            <v-list class="ml-5">
              <v-list-item>
                <v-chip color="gray" text-color="black">
                  <v-skeleton-loader v-if="loading" type="text" width="150px">
                  </v-skeleton-loader>
                  <template v-else>
                    Total:
                    {{ calculatedTotalPlantingReportsByProject }}
                  </template>
                </v-chip>
              </v-list-item>
              <v-list-item
                v-for="(amount, project) in projectTotals"
                :key="project"
              >
                <v-chip :color="PROJECT_COLORS[project]" text-color="white">
                  <v-skeleton-loader v-if="loading" type="text" width="150px">
                  </v-skeleton-loader>
                  <template v-else>
                    {{ PLANTING_PROJECTS[project] }}: {{ amount }} ({{
                      (
                        (amount / calculatedTotalPlantingReportsByProject) *
                        100
                      ).toFixed(2)
                    }}%)
                  </template>
                </v-chip>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" md="6">
            <PieChartProject :chartData="plantingReportByProjectsData" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-card>
        <LineChartPlatform :lineChartData="plantingReportByPlatformsData" />
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-card>
        <LineChartProject :lineChartData="plantingReportByProjectsData" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { API } from "../../api";
import LineChartPlatform from "../Charts/LineChartPlatform.vue";
import PieChartPlatform from "../Charts/PieChartPlatform.vue";
import PieChartProject from "../Charts/PieChartProject.vue";
import LineChartProject from "../Charts/LineChartProject.vue";
import {
  PLANTING_PROJECTS,
  PLATFORM_COLORS,
  PROJECT_COLORS,
} from "../../constants";

export default {
  name: "TreeReportsCharts",
  components: {
    DateRangePicker,
    LineChartPlatform,
    PieChartPlatform,
    PieChartProject,
    LineChartProject,
  },
  data() {
    return {
      PLANTING_PROJECTS,
      PLATFORM_COLORS,
      PROJECT_COLORS,
      plantingReportByPlatformsData: [],
      plantingReportByProjectsData: [],
      totalPlantingReport: 0,
      dateRange: {
        startDate: new Date(new Date().getTime() - 8 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
        previousPage: 0,
      },
      loading: false,
    };
  },
  filters: {
    date(date) {
      if (!date) return "";
      return date.toISOString().substr(0, 10).split("-").reverse().join(".");
    },
  },
  computed: {
    calculatedTotalPlantingReportsByPlatform() {
      return this.plantingReportByPlatformsData.reduce(
        (total, item) => total + item.reviewAmount,
        0
      );
    },
    calculatedTotalPlantingReportsByProject() {
      return this.plantingReportByProjectsData.reduce(
        (total, item) => total + item.reviewAmount,
        0
      );
    },
    ranges() {
      const startOfDay = new Date(new Date().setHours(2, 0, 0, 0));
      const endOfDay = new Date(new Date().setHours(23, 59, 59, 999));

      const thisMonthStart = new Date(
        startOfDay.getFullYear(),
        startOfDay.getMonth(),
        1
      );
      const thisMonthEnd = new Date(
        endOfDay.getFullYear(),
        endOfDay.getMonth() + 1,
        0
      );

      return {
        Today: [startOfDay, endOfDay],
        "Last 7 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 7
          ),
          endOfDay,
        ],
        "Last 30 days": [
          new Date(
            startOfDay.getFullYear(),
            startOfDay.getMonth(),
            startOfDay.getDate() - 30
          ),
          endOfDay,
        ],
        "This month": [thisMonthStart, thisMonthEnd],
        "This Year": [
          new Date(startOfDay.getFullYear(), 0, 1),
          new Date(endOfDay.getFullYear(), 11, 31),
        ],
        "Last month": [
          new Date(startOfDay.getFullYear(), startOfDay.getMonth() - 1, 1),
          new Date(endOfDay.getFullYear(), endOfDay.getMonth(), 0),
        ],
        "Last Year": [
          new Date(startOfDay.getFullYear() - 1, 0, 1),
          new Date(endOfDay.getFullYear() - 1, 11, 31),
        ],
      };
    },
    platformReviewAmounts() {
      return this.plantingReportByPlatformsData.map((item) => ({
        _id: { platformType: item.platformType },
        reviewAmount: item.reviewAmount,
      }));
    },
    projectReviewAmounts() {
      return this.plantingReportByProjectsData.map((item) => ({
        _id: { projectId: item.projectId },
        reviewAmount: item.reviewAmount,
      }));
    },
    platformTotals() {
      const totals = this.plantingReportByPlatformsData.reduce((acc, item) => {
        const platform = item._id.platformType;
        if (!acc[platform]) {
          acc[platform] = 0;
        }
        acc[platform] += item.reviewAmount;
        return acc;
      }, {});

      // Sort the platforms by review amount in descending order
      return Object.fromEntries(
        Object.entries(totals).sort(([, a], [, b]) => b - a)
      );
    },
    projectTotals() {
      const totals = this.plantingReportByProjectsData.reduce((acc, item) => {
        const projectId = item._id.projectId;
        if (!acc[projectId]) {
          acc[projectId] = 0;
        }
        acc[projectId] += item.reviewAmount;
        return acc;
      }, {});

      return Object.fromEntries(
        Object.entries(totals).sort(([, a], [, b]) => b - a)
      );
    },
  },
  created() {
    this.getTreeReports();
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.getTreeReports();
      }, 500);
    },
    async getTreeReports() {
      this.loading = true;

      try {
        const { getPlantingReportsByPlatform, getPlantingReportsByProject } =
          await API.getPlantingReportsStatistics({
            ...this.dateRange,
            scopes: "getPlantingReportsByPlatform,getPlantingReportsByProject",
          });

        this.plantingReportByPlatformsData = getPlantingReportsByPlatform;
        this.plantingReportByProjectsData = getPlantingReportsByProject;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

import Vue from "vue";
import Vuex from "vuex";
import { signInWithPopup, signOut, getAdditionalUserInfo } from "firebase/auth";
import { auth } from "../plugins/firebase";
import { API, setToken, removeToken } from "../api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    isGuest: false,
    user: {
      uuid: null,
      email: null,
      role: null,
    },
  },
  mutations: {
    USER_AUTH_SUCCESS(state, payload) {
      const { accessToken, email, uid } = payload;
      setToken(accessToken);
      state.user.email = email;
      state.user.uid = uid;
      state.isAuthenticated = true;
    },
    USER_LOGOUT(state) {
      state.isAuthenticated = false;
      removeToken();
    },
    SET_ACCOUNT_DATA(state, payload) {
      const { role, isGuest } = payload;
      state.user.role = role;
      state.isGuest = isGuest;
    },
  },
  actions: {
    async signIn({ commit }, user) {
      const userCredentialRequest = signInWithPopup(auth, user.provider);

      return userCredentialRequest
        .then(async (userCredential) => {
          const { isNewUser } = getAdditionalUserInfo(userCredential);
          if (user.provider && isNewUser) {
            await API.deleteUser(userCredential.user);
            return Promise.reject({ code: "auth/user-not-found" });
          } else {
            commit("USER_AUTH_SUCCESS", userCredential.user);
          }
          return Promise.resolve(userCredential);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async logOut({ commit }) {
      await signOut(auth);
      commit("USER_LOGOUT");
    },
    async getCurrentUser({ commit }) {
      const response = await API.getCurrentUser();
      if (response.role !== "admin") {
        throw new Error("forbidden");
      }
      commit("SET_ACCOUNT_DATA", response);
      return response;
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    isNotGuest: (state) => !state.isGuest,
    getUserInfo: (state) => state.user,
  },
});

<template>
  <div v-if="!loading">
    <v-breadcrumbs divider=">" :items="breadcrumbs" exact></v-breadcrumbs>
    <credit-note class="mb-10" :invoice="invoice"></credit-note>
  </div>
</template>

<script>
import { API } from "../api";
import CreditNote from "../components/Invoice/CreditNote.vue";

export default {
  name: "InvoiceCreditNote",
  components: {
    CreditNote,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      invoice: {},
    };
  },
  async created() {
    this.isLoading = true;
    try {
      this.invoice = await API.getInvoice(this.id);
    } catch (error) {
      // TODO: global error handler
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Companies",
          to: { name: "Companies" },
        },
        {
          text: this.invoice.companyName,
          to: { name: "Company", params: { id: this.invoice.ownedBy } },
        },
        {
          text: this.id,
          to: { name: "InvoiceView", params: { id: this.id } },
        },
        {
          text: "Credit Note",
        },
      ];
    },
  },
};
</script>

<template>
  <div class="chart-container">
    <LineChart
      v-if="chartReady"
      :key="chartKey"
      :data="chartData"
      :options="chartOptions"
    />
    <div v-else>Loading chart...</div>
    <!--<div v-if="debugInfo">
      <h3>Debug Info:</h3>
      <pre>{{ JSON.stringify(debugInfo, null, 2) }}</pre>
    </div> -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Line as LineChart } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import { PROJECT_COLORS } from "../../constants";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default defineComponent({
  name: "LineChartProject",
  components: { LineChart },
  props: {
    lineChartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      PROJECT_COLORS,
      chartReady: false,
      chartKey: 0,
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "category",
            title: {
              display: true,
              text: "Date",
            },
          },
          y: {
            type: "linear",
            title: {
              display: true,
              text: "Trees",
            },
            min: 0,
          },
        },
        plugins: {
          legend: {
            display: true,
          },
          title: {
            display: true,
            text: "Daily Trees by Project",
            font: {
              size: 16,
            },
          },
        },
      },
      // debugInfo: null,
    };
  },
  methods: {
    parseDate(dateString) {
      const [year, month, day] = dateString.split("-").map(Number);
      return new Date(year, month - 1, day);
    },
    formatDate(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return `${months[date.getMonth()]} ${date.getDate()}`;
    },
    getColor(projectId) {
      return (
        PROJECT_COLORS[projectId] ||
        "#" + Math.floor(Math.random() * 16777215).toString(16)
      );
    },
    prepareChartData() {
      if (!this.lineChartData || this.lineChartData.length === 0) {
        // console.warn("No data provided to the chart component");
        this.chartReady = false;
        return;
      }

      const groupedData = {};
      const projects = new Set();

      // Group data by date and platform
      this.lineChartData.forEach((item) => {
        const dateKey = `${item._id.year}-${item._id.month}-${item._id.day}`;
        if (!groupedData[dateKey]) {
          groupedData[dateKey] = {};
        }
        groupedData[dateKey][item._id.projectId] = item.reviewAmount;
        projects.add(item._id.projectId);
      });

      const sortedDates = Object.keys(groupedData).sort(
        (a, b) => this.parseDate(a) - this.parseDate(b)
      );

      // Create datasets for all platforms
      const datasets = Array.from(projects).map((projectId) => ({
        label: projectId,
        backgroundColor: this.getColor(projectId),
        borderColor: this.getColor(projectId),
        data: sortedDates.map((date) => groupedData[date][projectId] || 0),
        fill: false,
      }));

      this.chartData = {
        labels: sortedDates.map((date) =>
          this.formatDate(this.parseDate(date))
        ),
        datasets: datasets,
      };

      // this.debugInfo = {
      //   sortedData: sortedDates,
      //   labels: this.chartData.labels,
      //   dataPoints: this.chartData.datasets[0].data,
      // };

      this.chartReady = true;
      this.chartKey += 1; // Force re-render of the chart
    },
  },
  watch: {
    lineChartData: {
      handler() {
        this.prepareChartData();
      },
      immediate: true,
      deep: true,
    },
  },
});
</script>

<style scoped>
.chart-container {
  height: 400px;
  width: 100%;
}
</style>

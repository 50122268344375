import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialogChangeEmail),callback:function ($$v) {_vm.dialogChangeEmail=$$v},expression:"dialogChangeEmail"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Edit Email")]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Email","type":"email","rules":_vm.emailRules},model:{value:(_vm.editEmail),callback:function ($$v) {_vm.editEmail=$$v},expression:"editEmail"}})],1)],1),_c(VCardActions,[_c(VBtn,{on:{"click":_vm.closeDialog}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Confirm ")])],1)],1)],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialogConfirmation),callback:function ($$v) {_vm.dialogConfirmation=$$v},expression:"dialogConfirmation"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Warning! "),_c('br')]),_c(VCardText,[_vm._v(" Verify user identity before changing email, especially when altering the domain part. Security protocol must be followed! "),_c('br'),_c('br'),_vm._v(" You are about to change the users login email to "),_c('b',[_vm._v(_vm._s(_vm.editEmail))]),_vm._v(". ")]),_c(VCardActions,[_c(VBtn,{on:{"click":function($event){_vm.dialogConfirmation = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.changeEmail}},[(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"20","width":"2","color":"white"}}):_vm._e(),_vm._v(" Confirm ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app>
    <v-snackbar
      v-model="snackbar"
      absolute
      :timeout="5000"
      top
      right
      tile
      color="red accent-2"
      >{{ errorMessage }}</v-snackbar
    >
    <component :is="layout"> </component>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "./eventBus";
import SidebarLayout from "./layouts/SidebarLayout.vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import LoadingLayout from "./layouts/LoadingLayout.vue";

export default {
  name: "App",
  data() {
    return {
      loading: true,
      errorMessage: "",
      snackbar: false,
    };
  },
  components: {
    SidebarLayout,
    DefaultLayout,
    LoadingLayout,
  },
  created() {
    eventBus.$on("showError", (error) => {
      this.errorMessage = error;
      this.snackbar = true;
    });
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    layout() {
      if (this.loading) {
        return LoadingLayout;
      }
      return this.$route.meta.layout || "default-layout";
    },
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler: async function (v) {
        if (v) {
          await this.init();
        } else {
          this.loading = false;
        }
      },
    },
  },
  methods: {
    ...mapActions(["getCurrentUser", "logOut"]),
    async init() {
      this.loading = true;
      try {
        await this.getCurrentUser();
      } catch (error) {
        if (error.message === "forbidden" || error.response?.status === 401) {
          this.$router.push({
            name: "Logout",
            query: { message: "forbidden" },
          });
        } else {
          console.error(error);
        }
        this.loading = false;
        return;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.v-snack--absolute {
  z-index: 9999 !important;
}
</style>

function select(element) {
  let selectedText;

  if (element.nodeName === "SELECT") {
    element.focus();

    selectedText = element.value;
  } else if (element.nodeName === "INPUT" || element.nodeName === "TEXTAREA") {
    const isReadOnly = element.hasAttribute("readonly");

    if (!isReadOnly) {
      element.setAttribute("readonly", "");
    }

    element.select();
    element.setSelectionRange(0, element.value.length);

    if (!isReadOnly) {
      element.removeAttribute("readonly");
    }

    selectedText = element.value;
  } else {
    if (element.hasAttribute("contenteditable")) {
      element.focus();
    }

    const selection = window.getSelection();
    const range = document.createRange();

    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);

    selectedText = selection.toString();
  }

  return selectedText;
}

/**
 * @param {HTMLElement} target
 * @param {HTMLElement} trigger
 */
export default function copy({ target, trigger }) {
  let succeeded;
  if (typeof target === "undefined") {
    throw new Error('Invalid "target" value, use a valid Element');
  } else if (target && typeof target === "object" && target.nodeType === 1) {
    if (target.hasAttribute("disabled")) {
      throw new Error(
        'Invalid "target" attribute. Please use "readonly" instead of "disabled" attribute'
      );
    }
  } else {
    throw new Error('Invalid "target" value, use a valid Element');
  }

  select(target);

  try {
    succeeded = document.execCommand("copy");
  } catch (err) {
    succeeded = false;
  }
  if (trigger) {
    trigger.focus();
  }
  document.activeElement.blur();
  window.getSelection().removeAllRanges();
  return succeeded;
}

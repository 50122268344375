<template>
  <forest-table></forest-table>
</template>
<script>
import ForestTable from "../components/Forest/ForestTable.vue";

export default {
  name: "ForestsView",
  components: {
    ForestTable,
  },
};
</script>

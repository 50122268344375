<template>
  <v-card>
    <v-card-title>Invoice</v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td style="width: 200px">Id</td>
            <td>
              <router-link
                :to="{
                  name: 'InvoiceView',
                  params: { id: invoice.stripeInvoiceId },
                }"
              >
                {{ invoice.stripeInvoiceId }}
              </router-link>
            </td>
          </tr>
          <tr>
            <td>Number</td>
            <td>
              <a
                :href="`https://dashboard.stripe.com/invoices/${invoice.stripeInvoiceId}`"
                target="_blank"
              >
                {{ invoice.invoiceNumber }}
              </a>
            </td>
          </tr>
          <tr>
            <td>Current Invoice Status</td>
            <td>{{ invoice.status }}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{{ invoice.total / 100 }} {{ invoice.currency }}</td>
          </tr>
          <tr v-if="invoice.creditNotesAmount">
            <td>Credit Notes Amount</td>
            <td>
              {{ invoice.creditNotesAmount / 100 }} {{ invoice.currency }}
            </td>
          </tr>
          <tr v-if="invoice.creditNotes && invoice.creditNotes.length > 0">
            <td>Credit Notes History</td>
            <v-simple-table>
              <tbody>
                <tr>
                  <td style="width: 5px">Date</td>
                  <td style="width: 5px">Credit Note</td>
                  <td style="width: 5px">Amount</td>
                  <td style="width: 5px">PDF</td>
                </tr>
                <tr v-for="line in invoice.creditNotes" :key="line.id">
                  <td>{{ line.createdAt }}</td>
                  <td>
                    <a
                      :href="`https://dashboard.stripe.com/credit_notes/${line.stripeCreditNoteId}`"
                      target="_blank"
                    >
                      {{
                        line.stripeCreditNoteNumber || line.stripeCreditNoteId
                      }}
                    </a>
                  </td>
                  <td>{{ line.total / 100 }} {{ invoice.currency }}</td>
                  <td>
                    <v-btn
                      v-if="line.stripeCreditNotePdf"
                      class="ma-2"
                      text
                      icon
                      color="primary"
                      :href="line.stripeCreditNotePdf"
                      target="_blank"
                    >
                      <v-icon>picture_as_pdf</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-title>Issue Credit Note</v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td style="width: 200px">Reason</td>
            <td>
              <template v-if="creditNoteReasons">
                <v-row>
                  <v-col cols="3">
                    <v-select
                      :items="creditNoteReasons"
                      density="compact"
                      value="other"
                      v-model="reason"
                      item-text="name"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
            </td>
          </tr>
          <tr>
            <td>Memo</td>
            <td>
              <template v-if="creditNoteReasons">
                <v-row>
                  <v-col cols="3">
                    <v-select
                      label="Memo templates"
                      :items="
                        invoice.language === 'de'
                          ? creditNoteMemosDE
                          : creditNoteMemosEN
                      "
                      density="compact"
                      value="other"
                      v-model="memo"
                      item-text="item"
                      item-value="item"
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
              <v-responsive>
                <v-text-field
                  label="What's the reason for the removal"
                  v-model="memo"
                ></v-text-field>
              </v-responsive>
            </td>
          </tr>
          <tr>
            <td>How to credit</td>
            <td>
              <template v-if="creditNoteTypes">
                <v-row>
                  <v-col cols="3">
                    <v-select
                      :items="creditNoteTypes"
                      density="compact"
                      value="refund"
                      v-model="credit"
                      item-text="name"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
            </td>
          </tr>
          <tr>
            <td>Items to credit</td>
            <td>
              <template>
                <v-progress-linear
                  :active="actionsLoading"
                  :indeterminate="actionsLoading"
                  absolute
                  bottom
                  color="indigo darken-2"
                ></v-progress-linear>
              </template>
              <v-simple-table>
                <tbody v-if="invoice.type === $options.INVOICE_TYPES.TREES">
                  <tr>
                    <td style="width: 10px">Description</td>
                    <td style="width: 5px">Price per unit</td>
                    <td style="width: 5px">Quantity</td>
                    <td style="width: 5px">CreditAmount (incl. Tax)</td>
                  </tr>
                  <tr v-for="line in invoiceLines" :key="line.id">
                    <td>
                      <a
                        @click="projectId = line.projectId"
                        class="text-decoration-none"
                      >
                        {{ line.priceDescription }}
                      </a>
                    </td>
                    <td>{{ line.price / 100 }} {{ invoice.currency }}</td>
                    <td>
                      <div
                        v-if="
                          refundTrees.filter(
                            (el) => el.projectId === line.projectId
                          ).length > 0
                        "
                      >
                        {{
                          refundTrees.filter(
                            (el) => el.projectId === line.projectId
                          ).length
                        }}
                        ({{ line.quantity }})
                      </div>
                      <div v-else-if="refundTrees.length > 0">
                        0 ({{ line.quantity }})
                      </div>
                      <div v-else>{{ line.quantity }}</div>
                    </td>
                    <td>
                      <div
                        v-if="
                          refundTrees.filter(
                            (el) => el.projectId === line.projectId
                          ).length > 0
                        "
                      >
                        <div v-if="line.tax > 0 && line.taxPercentage">
                          {{
                            (
                              (refundTrees.filter(
                                (el) => el.projectId === line.projectId
                              ).length *
                                line.price) /
                                100 +
                              (line.taxPercentage / 100) *
                                ((refundTrees.filter(
                                  (el) => el.projectId === line.projectId
                                ).length *
                                  line.price) /
                                  100)
                            ).toFixed(2)
                          }}
                          {{ invoice.currency }}
                        </div>
                        <div v-else>
                          {{
                            (
                              (refundTrees.filter(
                                (el) => el.projectId === line.projectId
                              ).length *
                                line.price) /
                              100
                            ).toFixed(2)
                          }}
                          {{ invoice.currency }}
                        </div>
                      </div>
                      <div v-else-if="refundTrees.length > 0">0</div>
                      <div v-else>
                        {{ line.total / 100 }} {{ invoice.currency }}
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="width: 10px">Description</td>
                    <td style="width: 5px">Quantity</td>
                    <td style="width: 5px">CreditAmount excl. tax</td>
                  </tr>
                  <tr v-for="line in invoiceLines" :key="line.id">
                    <td>{{ line.priceDescription }}</td>
                    <td>
                      <span v-if="line.subtotal > 0">{{ line.quantity }}</span>
                    </td>
                    <td>
                      <span v-if="line.subtotal > 0">
                        {{ line.subtotal / 100 }} {{ invoice.currency }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <div v-if="projectId">
                <template>
                  <v-card>
                    <v-card-title>
                      Invoice Item Trees
                      {{ $options.PLANTING_PROJECTS[projectId] }}
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="trees"
                      :server-items-length="totalAmount"
                      item-key="id"
                      class="elevation-1"
                      v-model="refundTrees"
                      show-select
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      :options.sync="options"
                    >
                      <template v-slot:[`item.text`]="{ item }">
                        <v-tooltip top open-delay="1000">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" v-if="item.text">
                              {{ item.text.substring(0, 100) + "..." }}
                            </span>
                          </template>
                          <span>{{ item.text }}</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card>
                </template>
                <template>
                  <v-card class="mx-auto">
                    <v-card-text v-if="refundTrees.length > 0">
                      {{ refundTrees.length }} selected tree(s):
                      {{ refundTrees.map((tree) => tree.name).join(", ") }}
                    </v-card-text>
                    <v-card-text v-else>All trees</v-card-text>
                  </v-card>
                </template>
              </div>
            </td>
          </tr>
          <tr>
            <td>Actions</td>
            <td>
              <template>
                <v-card>
                  <v-btn color="primary" @click="previewCreditNote()">
                    Preview Credit Note
                  </v-btn>
                </v-card>
              </template>
            </td>
          </tr>
          <tr v-if="previewedCreditNote">
            <td>Total credit note</td>
            <td>
              <template>
                <v-card-text>
                  {{ previewedCreditNote.total / 100 }}
                  {{ previewedCreditNote.currency }}
                </v-card-text>
                <v-dialog v-model="dialog" persistent max-width="550">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!isIssueCreditNoteAllowed"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Issue Credit Note
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Issue Credit Note
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td style="width: 50px">Number</td>
                              <td>{{ previewedCreditNote.number }}</td>
                            </tr>
                            <tr>
                              <td>Memo</td>
                              <td>{{ memo }}</td>
                            </tr>
                            <tr>
                              <td>Reason</td>
                              <td>{{ reason }}</td>
                            </tr>
                            <tr>
                              <td>How to credit</td>
                              <td>{{ credit }}</td>
                            </tr>
                            <tr>
                              <td>Total</td>
                              <td>
                                {{ previewedCreditNote.total / 100 }}
                                {{ previewedCreditNote.currency }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="dialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          dialog = false;
                          issueCreditNote();
                        "
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </td>
          </tr>
          <tr v-if="creditNoteError">
            <td>Credit Note error</td>
            <td>
              <v-alert dense outlined type="error">
                {{ creditNoteError }}
              </v-alert>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { API } from "../../api";
import {
  DEFAULT_ITEMS_PER_PAGE,
  INVOICE_STATUS,
  INVOICE_TYPES,
  PLANTING_PROJECTS,
} from "../../constants";

export default {
  name: "CreditNote",
  DEFAULT_ITEMS_PER_PAGE,
  INVOICE_STATUS,
  INVOICE_TYPES,
  PLANTING_PROJECTS,
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      memo: null,
      reason: null,
      dialog: false,
      credit: "refund",
      trees: [],
      refundTrees: [],
      isIssueCreditNoteAllowed: false,
      toSubmitInvoiceLineItems: null,
      previewedCreditNote: null,
      creditNoteError: null,
      loading: false,
      actionsLoading: false,
      projectId: null,
      search: "",
      creditNoteTypes: [
        {
          name: "Credit Balance",
          value: "credit_balance",
        },
        {
          name: "Refund",
          value: "refund",
        },
      ],
      creditNoteReasons: [
        {
          name: "Other",
          value: null,
        },
        {
          name: "Duplicate charge",
          value: "duplicate",
        },
        {
          name: "Fraudulent charge",
          value: "fraudulent",
        },
        {
          name: "Order change",
          value: "order_change",
        },
        {
          name: "Product unsatisfactory",
          value: "product_unsatisfactory",
        },
      ],
      creditNoteMemosDE: [
        "[NUMBER] Baum wurde erstattet, weil der Kunde den Baum löschen wollte (gefälschte Bewertung).",
        "[NUMBER] gelöschte Bäume.",
        "[NUMBER] Baum wurde erstattet, weil der Kunde den Baum löschen wollte (falscher Name) und stattdessen einen anderen gepflanzt hat.",
        "Löschung [NUMBER] Baumes, da Bewertung auf [PLATFORM] gelöscht wurde.",
        "Druckbestellung mit dem falschen Standort, Kunde will mit anderem Standort Karten bestellen.",
        "Gutschrift und Rückerstattung auf Basis umgehender Kündigung am finalen Testtag.",
        "Kunde hat falsche Anzahl von Karten bestellt, bestellt gleich [NUMBER] Stück. Credit Balance wird angewandt.",
      ],
      creditNoteMemosEN: [
        "[NUMBER] tree refunded because customer wanted to delete tree (fake review).",
        "[NUMBER] deleted trees.",
        "[NUMBER] tree refunded because customer wanted to delete tree (wrong name) & planted another one instead.",
        "Customer's sub ([DATE] to [DATE]) was deactivated because of uncollectable invoice. We reactivated it -> new sub [DATE] to [DATE] -> refund of [DATE_RANGE].",
        "Refund because account was deactivated for [DATE_RANGE]",
        "Customer cancelled another [NUMBER] forests -> just [NUMBER] forest left.",
        "Customer has [NUMBER] forests and not [NUMBER] -> refund of [NUMBER] forest.",
        "Print order cancelled.",
        "Customer wishes to cancel.",
        "Unused [PLAN_NAME] Plan",
        "Refund for [NUMBER] months because customer cancelled.",
        "Refund because customer wants to stop participating.",
      ],
      headers: [
        { text: "Name", value: "name" },
        { text: "Date", value: "date" },
        { text: "Score", value: "score" },
        { text: "Project", value: "plantingProject" },
        { text: "Occasion", value: "occasion" },
        { text: "Platform Type", value: "platformType" },
        { text: "Tree Type", value: "type" },
        { text: "Text", value: "text" },
      ],
      options: {
        page: 1,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      totalAmount: 0,
    };
  },
  watch: {
    refundTrees: {
      async handler(val) {
        if (!val) {
          this.refundTrees = [];
        }
        this.isIssueCreditNoteAllowed = false;
      },
      immediate: true,
      deep: true,
    },
    projectId: {
      handler: "debounceFetching",
    },
    search: {
      handler: "debounceFetching",
    },
    options: {
      handler: "debounceFetching",
      deep: true,
    },
  },
  computed: {
    invoiceLines() {
      return this.invoice?.lines;
    },
    searchQuery() {
      const trimmedSearch = this.search.trim();
      return trimmedSearch === "" ? null : trimmedSearch;
    },
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.isIssueCreditNoteAllowed = false;
      this.timerId = setTimeout(() => {
        this.getInvoiceTrees();
      }, 500);
    },
    async getInvoiceTrees() {
      this.loading = true;

      if (!this.projectId) {
        this.loading = false;
        return;
      }

      if (this.projectId) {
        this.trees = [];
      }

      await API.getInvoiceTrees({
        stripeInvoiceId: this.invoice.stripeInvoiceId,
        params: {
          projectId: this.projectId,
          sortBy: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? "desc" : "asc",
          page: this.options.page,
          pageSize:
            this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage,
          query: this.searchQuery,
        },
      })
        .then((response) => {
          this.trees = response.data;
          this.totalAmount = response.count;
        })
        .catch((error) => {
          this.creditNoteError = `Error: ${error.response?.data?.message}`;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async previewCreditNote() {
      this.creditNoteError = false;
      this.actionsLoading = true;
      const previewTreeIds = this.refundTrees;

      this.toSubmitInvoiceLineItems = this.invoiceLines
        .filter((i) => i.subtotal > 0)
        ?.map((el) => {
          const item = { id: el.id };
          if (this.invoice.type === INVOICE_TYPES.TREES) {
            if (previewTreeIds?.length > 0) {
              if (
                previewTreeIds.filter(
                  (tr) => tr.projectId ?? tr.plantingProjectId === el.projectId
                ).length > 0
              ) {
                item.treeIds = previewTreeIds
                  .filter(
                    (tr) =>
                      tr.projectId ?? tr.plantingProjectId === el.projectId
                  )
                  .map((tx) => tx.id);
              } else {
                delete item.id;
                return;
              }
            } else {
              item.projectId = el.projectId;
            }
          } else {
            const invoiceLine = this.invoice.lines.find(
              (ex) => ex.id === el.id
            );
            if (el.subtotal <= invoiceLine?.subtotal) {
              item.amount = el.subtotal;
            } else {
              item.quantity = el.quantity;
            }
          }
          return item;
        })
        .filter((item) => !!item);

      await API.previewCreditNote({
        stripeInvoiceId: this.invoice.stripeInvoiceId,
        data: { invoiceLineItems: this.toSubmitInvoiceLineItems },
      })
        .then((response) => {
          this.previewedCreditNote = response;
          this.isIssueCreditNoteAllowed = true;
        })
        .catch((error) => {
          this.creditNoteError = `Error: ${error.response?.data?.message}`;
        })
        .finally(() => {
          this.actionsLoading = false;
        });
    },
    async issueCreditNote() {
      this.actionsLoading = true;
      await API.issueCreditNote({
        stripeInvoiceId: this.invoice.stripeInvoiceId,
        data: {
          invoiceLineItems: this.toSubmitInvoiceLineItems,
          memo: this.memo,
          reason: this.reason,
          credit: this.credit,
        },
      })
        .then(() => {
          this.$router.push({
            name: "Company",
            params: { id: this.invoice.ownedBy },
          });
        })
        .catch((error) => {
          this.creditNoteError = `Error: ${error.response?.data?.message}`;
        })
        .finally(() => {
          this.actionsLoading = false;
        });
    },
  },
};
</script>

<template>
  <div></div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "TheLogout",
  async created() {
    await this.logOut();
    this.$router.push({ name: "SignIn", query: this.$route.query });
  },
  methods: {
    ...mapActions(["logOut"]),
  },
};
</script>

<template>
  <v-card>
    <v-card-title>
      Users
      <v-spacer></v-spacer>
      <v-text-field
        v-model.trim="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :server-items-length="totalAmount"
      :loading="loading"
      :headers="headers"
      :items="users"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': $options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      }"
      fixed-header
      :options.sync="options"
    >
      <template v-slot:[`item.email`]="{ item }">
        <router-link :to="{ name: 'User', params: { id: item.id } }">{{
          item.email
        }}</router-link>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.lastLoginAt`]="{ item }">
        <template v-if="item.lastSignInTime">
          {{ formatDate(item.lastSignInTime) }}
        </template>
        <template v-else>
          {{ formatDate(item.createdAt) }}
        </template>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <v-btn
          v-show="isNotGuest"
          class="ma-2"
          text
          icon
          color="primary"
          @click.prevent="loginAsCustomer(item.id)"
        >
          <v-icon>login</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  DEFAULT_ITEMS_PER_PAGE_OPTIONS,
  DEFAULT_ITEMS_PER_PAGE,
} from "../../constants";
import { loginAsCustomer, formatDate } from "../../helpers";
import { API } from "../../api";

export default {
  name: "UsersTable",
  DEFAULT_ITEMS_PER_PAGE_OPTIONS,
  data() {
    return {
      formatDate,
      loginAsCustomer,
      timerId: null,
      loading: true,
      users: [],
      totalAmount: 0,
      search: "",
      options: {
        page: 1,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      headers: [
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        { text: "Joined at", value: "createdAt", sortable: true },
        {
          text: "Last login",
          value: "lastLoginAt",
          sortable: false,
        },
        { text: "Log in as customer", value: "id", sortable: false },
      ],
    };
  },
  watch: {
    search: {
      handler: "debounceFetching",
    },
    options: {
      handler: "debounceFetching",
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["isNotGuest"]),
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.getUsers();
      }, 500);
    },
    async getUsers() {
      this.loading = true;
      try {
        const users = await API.getUsers({
          sortBy: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? "desc" : "asc",
          page: this.options.page,
          pageSize:
            this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage,
          query: this.search === "" ? null : this.search,
        });
        this.users = users.data;
        this.totalAmount = users.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <!-- Add submenu -->
    <div class="submenu mb-4">
      <button
        v-for="(item, index) in menuItems"
        :key="index"
        @click="activeView = item.value"
        :class="['menu-button', { active: activeView === item.value }]"
      >
        {{ item.label }}
      </button>
    </div>

    <!-- Conditional rendering of components -->
    <tree-reports-charts v-if="activeView === 'charts'" class="mb-10" />
    <tree-reports-table v-if="activeView === 'table'" class="mb-10" />
  </div>
</template>

<script>
import TreeReportsCharts from "./TreeReportsCharts.vue";
import TreeReportsTable from "./TreeReportsTable.vue";

export default {
  name: "TreeReports",
  components: {
    TreeReportsCharts,
    TreeReportsTable,
  },
  data() {
    return {
      activeView: "charts",
      menuItems: [
        { label: "Charts", value: "charts" },
        { label: "Table", value: "table" },
      ],
    };
  },
};
</script>

<style scoped>
.submenu {
  display: flex;
  gap: 1rem;
}

.menu-button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  width: 150px;
}

.menu-button.active {
  background: rgb(226, 225, 225);
  color: black;
  border-color: rgb(226, 225, 225);
}
</style>

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto"},[(_vm.loading)?_c(VSkeletonLoader,{attrs:{"type":"text"}}):_vm._e(),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"3"}},[_c(VCardTitle,[_vm._v("Visitors")]),_c(VCardSubtitle,[[_c('DateRangePicker',{attrs:{"ranges":_vm.ranges,"opens":"right","singleDatePicker":false,"maxDate":new Date()},on:{"update":_vm.getChartData},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(_vm._f("date")(picker.startDate))+" - "+_vm._s(_vm._f("date")(picker.endDate))+" ")]}}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c(VSelect,{attrs:{"items":_vm.visitorLogTypes,"item-value":"id","item-text":"name","label":"Select Type","multiple":""},on:{"change":_vm.getChartData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c(VChip,[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"text-grey text-caption align-self-center"},[_vm._v(" (+"+_vm._s(_vm.selectedVisitorLogType.length - 2)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedVisitorLogType),callback:function ($$v) {_vm.selectedVisitorLogType=$$v},expression:"selectedVisitorLogType"}})]],2),(_vm.mostCrawlRequestCompanies.length > 0)?[_c(VCardText,{staticClass:"text-h6"},[_vm._v(" 5 Companies with Most Crawl Requests ")]),_vm._l((_vm.mostCrawlRequestCompanies),function(item){return _c(VListItem,{key:item.id,attrs:{"variant":"contained"}},[_c('router-link',{attrs:{"to":{ name: 'Company', params: { id: item.id } }}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.shortenText(item.name, 25))+" ("+_vm._s(item.count)+") ")])],1)],1)})]:_vm._e(),_c(VSpacer),(_vm.mostCrawlRequestForests.length > 0)?[_c(VCardText,{staticClass:"text-h6"},[_vm._v(" 5 Forests with Most Crawl Requests ")]),_vm._l((_vm.mostCrawlRequestForests),function(item){return _c(VListItem,{key:item.id,attrs:{"variant":"contained"}},[_c('router-link',{attrs:{"to":{ name: 'Forest', params: { id: item.id } }}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.shortenText(item.name, 25))+" ("+_vm._s(item.count)+") ")])],1)],1)})]:_vm._e()],2),_c(VCol,{attrs:{"cols":"9"}},[[_c('bar-chart',{attrs:{"chartData":_vm.formattedChartData}})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Invoices "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invoices,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalAmount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'InvoiceView',
          params: { id: item.id },
        }}},[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.data.amountDue",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.amountDue)+" "+_vm._s(item.currency)+" ")]}},{key:"item.links",fn:function(ref){
        var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","href":("https://dashboard.stripe.com/invoices/" + (item.id)),"target":"_blank"}},on),[_c(VIcon,[_vm._v("link")])],1)]}}],null,true)},[_c('span',[_vm._v("Stripe")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(item.id)?_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadInvoice(item.id)}}},on),[_c(VIcon,[_vm._v("picture_as_pdf")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("PDF")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(item.breakdownPdf)?_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","href":item.breakdownPdf,"target":"_blank"}},on),[_c(VIcon,[_vm._v("description")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Breakdown")])])]}},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [(item.paid && !item.fullRefunded)?_c('router-link',{attrs:{"to":{ name: 'InvoiceCreditNote', params: { id: item.id } }}},[_vm._v(" Issue Credit Note ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="!loading">
    <v-breadcrumbs divider=">" :items="breadcrumbs" exact></v-breadcrumbs>
    <forest-profile
      class="mb-10"
      :forest="forest"
      :forestCrawlRequests="forestCrawlRequests"
    ></forest-profile>
    <forest-platforms-table
      :platforms="forest.platforms"
    ></forest-platforms-table>
    <forest-trees
      v-show="isNotGuest"
      :trees="trees"
      :forestId="id"
    ></forest-trees>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { API } from "../api";
import ForestProfile from "../components/Forest/ForestProfile.vue";
import ForestPlatformsTable from "../components/Forest/ForestPlatformsTable.vue";
import ForestTrees from "../components/Forest/ForestTrees.vue";

export default {
  name: "ForestView",
  components: {
    ForestPlatformsTable,
    ForestProfile,
    ForestTrees,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      forest: null,
      trees: [],
      loading: true,
      forestCrawlRequests: 0,
    };
  },
  watch: {
    id: {
      async handler(id) {
        this.loading = true;
        try {
          const [forest, trees, crawlReviewNumber] = await Promise.all([
            API.getForest({ forestId: id }),
            API.getForestTrees({ forestId: id }),
            API.getVisitorNumber({
              params: {
                forestId: id,
                type: "crawlReview",
              },
            }),
          ]);
          this.forest = { ...forest };
          this.trees = trees;
          this.forestCrawlRequests = crawlReviewNumber.count;
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["isNotGuest"]),
    breadcrumbs() {
      return [
        {
          text: "Forests",
          to: { name: "Forests" },
          exact: true,
        },
        {
          text: this.forest.name,
        },
      ];
    },
  },
};
</script>

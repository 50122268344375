<template>
  <v-card>
    <v-card-title>Platforms</v-card-title>

    <v-data-table :headers="headers" :items="platforms">
      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <a :href="getPlatformDetail(item.platform).url" target="_blank">
                {{ shortenText(item.name, 30) }}
              </a>
            </span>
          </template>
          <span>{{ getPlatformDetail(item.platform).detail }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <platform-logo :platform="item.type"></platform-logo>
      </template>
      <template v-slot:[`item.platform`]="{ item }">
        {{ item.platform.uniqueId }}
      </template>
      <template v-slot:[`item.plantingReviewOption`]="{ item }">
        <template
          v-if="
            item.plantingReviewOption ===
            $options.TREE_PLANTING_OPTION.ONLY_NEW_REVIEWS
          "
        >
          Only new reviews
        </template>
        <template v-else>All reviews</template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template v-if="item.type === 'google' && !item.keys.gmb"></template>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="ma-2"
              text
              icon
              color="primary"
              @click="openEditPlatformModal(item)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Platform</span>
        </v-tooltip>
        <forest-platform-edit :platform="item" ref="platformEditModal">
        </forest-platform-edit>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { PLATFORM_TYPES, TREE_PLANTING_OPTION } from "../../constants";
import PlatformLogo from "../Base/PlatformLogo.vue";
import { shortenText } from "../../helpers";
import ForestPlatformEdit from "./ForestPlatformEdit.vue";

export default {
  name: "ForestPlatformsTable",
  PLATFORM_TYPES,
  TREE_PLANTING_OPTION,
  components: {
    PlatformLogo,
    ForestPlatformEdit,
  },
  props: {
    platforms: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      shortenText,
      headers: [
        {
          text: "Name",
          sortable: true,
          value: "name",
        },
        {
          text: "Platform",
          sortable: true,
          value: "type",
        },
        {
          text: "Unique ID",
          value: "platform",
        },
        {
          text: "Review amount",
          sortable: true,
          value: "reviewAmount",
        },
        {
          text: "Review amount on platform",
          sortable: true,
          value: "reviewAmountOnPlatform",
        },
        {
          text: "Rating",
          sortable: true,
          value: "score",
        },
        {
          text: "Planted trees",
          sortable: true,
          value: "reviewTreeAmount",
        },
        {
          text: "Plant trees for",
          sortable: true,
          value: "plantingReviewOption",
        },
        {
          text: "Last report",
          sortable: true,
          value: "lastReportDate",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    getPlatformDetail(platform) {
      let detail = "";
      let url = "";
      switch (platform.type) {
        case PLATFORM_TYPES.AMAZON:
          url = platform[PLATFORM_TYPES.AMAZON]?.link;
          detail = platform.name;
          break;
        case PLATFORM_TYPES.FACEBOOK:
          url = `https://www.facebook.com/${platform.uniqueId}`;
          detail = platform.name;
          break;
        case PLATFORM_TYPES.GOOGLE:
          url = platform[PLATFORM_TYPES.GOOGLE]?.url;
          detail = `${platform[PLATFORM_TYPES.GOOGLE]?.address}, ${url}`;
          break;
        case PLATFORM_TYPES.TRUSTPILOT:
          detail =
            platform[PLATFORM_TYPES.TRUSTPILOT]?.businessUnit?.identifyingName;
          url = platform[PLATFORM_TYPES.TRUSTPILOT]?.pageUrl;
          break;
        case PLATFORM_TYPES.KUNUNU:
          detail = platform[PLATFORM_TYPES.KUNUNU].simpleName;
          url = `https://kununu.com/${
            platform[PLATFORM_TYPES.KUNUNU].countryCode
          }/${platform[PLATFORM_TYPES.KUNUNU].slug}`;
          break;
        case PLATFORM_TYPES.GLASSDOOR:
          detail = platform[PLATFORM_TYPES.GLASSDOOR]?.employer?.shortName;
          url = `https://glassdoor.com/${
            platform[PLATFORM_TYPES.GLASSDOOR]?.links?.overviewUrl
          }`;
          break;
        default:
          break;
      }
      return {
        url,
        detail,
      };
    },
    openEditPlatformModal() {
      this.$refs.platformEditModal.dialogForestPlatformEdit = true;
    },
  },
};
</script>

<template>
  <v-main class="align-center">
    <div class="text-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "LoadingLayout",
};
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v("Platforms")]),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.platforms},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('a',{attrs:{"href":_vm.getPlatformDetail(item.platform).url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.shortenText(item.name, 30))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getPlatformDetail(item.platform).detail))])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('platform-logo',{attrs:{"platform":item.type}})]}},{key:"item.platform",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.platform.uniqueId)+" ")]}},{key:"item.plantingReviewOption",fn:function(ref){
var item = ref.item;
return [(
          item.plantingReviewOption ===
          _vm.$options.TREE_PLANTING_OPTION.ONLY_NEW_REVIEWS
        )?[_vm._v(" Only new reviews ")]:[_vm._v("All reviews")]]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(item.type === 'google' && !item.keys.gmb)?void 0:_vm._e(),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.openEditPlatformModal(item)}}},on),[_c(VIcon,[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Platform")])]),_c('forest-platform-edit',{ref:"platformEditModal",attrs:{"platform":item}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Users "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"server-items-length":_vm.totalAmount,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.users,"footer-props":{
      'items-per-page-options': _vm.$options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
    },"fixed-header":"","options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'User', params: { id: item.id } }}},[_vm._v(_vm._s(item.email))])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.lastLoginAt",fn:function(ref){
    var item = ref.item;
return [(item.lastSignInTime)?[_vm._v(" "+_vm._s(_vm.formatDate(item.lastSignInTime))+" ")]:[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.isNotGuest),expression:"isNotGuest"}],staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.loginAsCustomer(item.id)}}},[_c(VIcon,[_vm._v("login")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
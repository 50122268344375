<template>
  <div>
    <v-breadcrumbs divider=">" :items="breadcrumbs" exact></v-breadcrumbs>
    <company-crawl-requests :id="id"></company-crawl-requests>
  </div>
</template>

<script>
import CompanyCrawlRequests from "@/components/Company/CompanyCrawlRequests.vue";

export default {
  name: "CompanyCrawlRequestsView",
  components: {
    CompanyCrawlRequests,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Companies",
          to: { name: "Companies" },
          exact: true,
        },
        {
          text: this.name,
          to: {
            name: "Company",
            params: { id: this.id },
          },
          exact: true,
        },
        {
          text: "Crawl Requests",
        },
      ];
    },
  },
};
</script>

<template>
  <tree-reports></tree-reports>
</template>

<script>
import TreeReports from "../components/Tree/TreeReports.vue";

export default {
  name: "TreeReportsView",
  components: {
    TreeReports,
  },
};
</script>
